/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * JSON Web Key ({@link https://www.rfc-editor.org/rfc/rfc7517 JWK}). "RSA", "EC", "OKP", and "oct"
 * key types are supported.
 */
export interface JWK {
  /** JWK "alg" (Algorithm) Parameter. */
  alg?: string;
  crv?: string;
  d?: string;
  dp?: string;
  dq?: string;
  e?: string;
  /** JWK "ext" (Extractable) Parameter. */
  ext?: boolean;
  k?: string;
  /** JWK "key_ops" (Key Operations) Parameter. */
  key_ops?: string[];
  /** JWK "kid" (Key ID) Parameter. */
  kid?: string;
  /** JWK "kty" (Key Type) Parameter. */
  kty?: string;
  n?: string;
  oth?: {
    t?: string;
    r?: string;
    d?: string;
  }[];
  p?: string;
  q?: string;
  qi?: string;
  /** JWK "use" (Public Key Use) Parameter. */
  use?: string;
  x?: string;
  y?: string;
  /** JWK "x5c" (X.509 Certificate Chain) Parameter. */
  x5c?: string[];
  /** JWK "x5t" (X.509 Certificate SHA-1 Thumbprint) Parameter. */
  x5t?: string;
  /** "x5t#S256" (X.509 Certificate SHA-256 Thumbprint) Parameter. */
  "x5t#S256"?: string;
  /** JWK "x5u" (X.509 URL) Parameter. */
  x5u?: string;
  [key: string]: any;
}

/** JSON Web Key Set */
export interface JSONWebKeySet {
  keys: JWK[];
}

export interface OpenIdFederation {
  iss: string;
  sub: string;
  authority_hints?: string[];
  /** JSON Web Key Set */
  jwks: JSONWebKeySet;
  metadata?: {
    federation_entity: {
      federation_fetch_endpoint: string;
    };
    federation_info?: {
      name: string;
    };
  };
}

export interface DidVerificationMethod {
  id: string;
  type: "JsonWebKey2020";
  controller: string;
  /**
   * JSON Web Key ({@link https://www.rfc-editor.org/rfc/rfc7517 JWK}). "RSA", "EC", "OKP", and "oct"
   * key types are supported.
   */
  publicKeyJwk: JWK;
}

export interface DidJson {
  "@context"?: string[];
  id: string;
  verificationMethod: DidVerificationMethod[];
  authentication?: string[];
}

export interface IErrorResponse {
  message: string;
}

/** @example {"name":"test","domain":"test.minisuomi.fi","did":"did:web:test.minisuomi.fi:api:verifiers:test"} */
export interface VerifierCreationParams {
  name: string;
  did: string;
  domain: string;
  api: string;
}

export interface JWKPair {
  /**
   * JSON Web Key ({@link https://www.rfc-editor.org/rfc/rfc7517 JWK}). "RSA", "EC", "OKP", and "oct"
   * key types are supported.
   */
  publicKey: JWK;
  /**
   * JSON Web Key ({@link https://www.rfc-editor.org/rfc/rfc7517 JWK}). "RSA", "EC", "OKP", and "oct"
   * key types are supported.
   */
  privateKey: JWK;
}

export interface SubmissionRequirements {
  name: string;
  purpose: string;
  rule: "pick" | "all";
  /** @format double */
  count: number;
  from: string;
}

/** Constraint fields are used to filter credentials by credential fields */
export interface ConstraintField {
  /**
   * Required, JSONPath string expression, the credential needs to have this path
   * https://identity.foundation/presentation-exchange/#jsonpath-syntax-definition
   * For example $ is used for the root object/element
   */
  path: string[];
  id?: string;
  purpose?: string;
  name?: string;
  /** optional, JSON Schema descriptor used to filter against the values returned from evaluation of the JSONPath string expressions in the path array. */
  filter?: {
    contains?: {
      pattern?: string;
      type?: "string" | "array" | "boolean" | "number";
      const?: string;
    };
    pattern?: string;
    enum?: string;
    type?: "string" | "array" | "boolean" | "number";
    const?: string;
  };
  intent_to_retain?: boolean;
}

/**
 * https://identity.foundation/presentation-exchange/
 * Input Descriptors are used by a Verifier to describe the information required of a Holder before an interaction can proceed.
 */
export interface InputDescriptor {
  id: string;
  constraints: {
    limit_disclosure?: "required";
    fields: ConstraintField[];
  };
  format?:
    | string
    | Record<
        string,
        {
          alg?: string[];
        }
      >;
  name?: string;
  purpose?: string;
  group?: string[];
}

/**
 * https://identity.foundation/presentation-exchange/
 * Presentation Definitions are objects that articulate what proofs a Verifier requires. These help the Verifier to decide how or whether to interact with a Holder. Presentation Definitions are composed of inputs, which describe the forms and details of the proofs they require, and optional sets of selection rules, to allow Holders flexibility in cases where many different types of proofs may satisfy an input requirement.
 */
export interface PresentationDefinition {
  id: string;
  name?: string;
  format?: Record<
    string,
    {
      alg: string[];
    }
  >;
  submission_requirements?: SubmissionRequirements[];
  input_descriptors: InputDescriptor[];
}

export interface InitiateTransactionRequest {
  /**
   * https://identity.foundation/presentation-exchange/
   * Presentation Definitions are objects that articulate what proofs a Verifier requires. These help the Verifier to decide how or whether to interact with a Holder. Presentation Definitions are composed of inputs, which describe the forms and details of the proofs they require, and optional sets of selection rules, to allow Holders flexibility in cases where many different types of proofs may satisfy an input requirement.
   */
  presentationDefinition: PresentationDefinition;
  redirectUri: string;
  state: string;
  client_id: string;
  client_id_scheme: "pre-registered" | "redirect_uri" | "did" | "x509_san_dns";
  response_type?: "vp_token";
  nonce?: string;
  response_uri?: string;
  request_uri?: string;
  request_uri_method?: string;
  response_mode?: "direct_post";
  iss?: string;
  /** @format double */
  exp?: number;
  client_metadata?: {
    authorization_encrypted_response_enc?: any;
    authorization_encrypted_response_alg?: any;
    authorization_signed_response_alg?: any;
    /** JSON Web Key Set */
    jwks?: JSONWebKeySet;
    vp_formats?: Record<string, Record<string, string[]>>;
  };
}

export interface SimpleClaim {
  name: string;
  type?: "string" | "number" | "boolean";
  constant?: string;
  pattern?: string;
}

export interface IAuthorizeResponse {
  responseUri: string;
}

export interface SimplifiedCredential {
  issuer: string;
  /**
   * JSON Web Key ({@link https://www.rfc-editor.org/rfc/rfc7517 JWK}). "RSA", "EC", "OKP", and "oct"
   * key types are supported.
   */
  issuerKey?: JWK;
  payload: Record<string, any>;
}

export interface SimplifiedPresentationPayload {
  holder: string;
  issuer?: string;
  client_id_scheme: "pre-registered" | "did" | "redirect_uri" | "x509_san_dns";
  /** @format double */
  validFrom?: number;
  /** @format double */
  validTo?: number;
  credentials: SimplifiedCredential[];
}

export interface TestiaineistoCompanyData {
  ytjData: {
    legalForm: {
      validityEndDate: string | null;
      validityStartDate: string | null;
      source: string | null;
      type: string | null;
    };
    businessLine: {
      validityEndDate: string | null;
      validityStartDate: string | null;
      source: string | null;
      businessLineCode: string | null;
      businessLineType: string | null;
    };
    [key: string]: any;
  };
  virreData: {
    legalRepresentations: {
      company: string;
      representationRegistrationDate: string | null;
      representationSigningCode: string | null;
      representationText: string;
      representationHeader: string;
      [key: string]: any;
    }[];
    companyRoles: {
      status: string;
      type: string;
      role: string;
      person: string;
      company: string;
    }[];
    code: string;
    state: string;
    status: string;
    companyForm: string;
    placeOfBusiness: string | null;
    companyName: string;
    source: "Virre";
    [key: string]: any;
  };
  code: string;
  [key: string]: any;
}

export interface TestDataPerson {
  /** guid */
  ID: string;
  FirstNames: string;
  LastName: string;
  /** for example "2002-03-26T00:00:00.000Z" */
  Birthday: string;
  /** @format double */
  Gender: number;
  /** for example "FI" */
  PrimaryLanguage: string;
  /** for example "FI" */
  Nationality: string;
  /**
   * 0 means not married, 1 means married
   * @format double
   */
  MaritalStatus: number;
  /** @format double */
  ChildCount: number;
  /**
   * boolean 0/1
   * @format double
   */
  HasTrustee: number;
  /**
   * boolean 0/1
   * @format double
   */
  HasProtectionOrder: number;
  DateOfDeath: string | null;
  /** for example 260302A997V */
  Code: string;
  /** id of the home municipality (kotikunta) */
  HomeMunicipality: string;
}

export interface TestDataCompanyRole {
  Type: string;
  Role: string;
  Person: string;
  Company: string;
  OwnerID: string;
  ID: string;
}

export interface TestDataCompany {
  PeppolParticipantID: string;
  DateOfRegistration: string;
  IndustryCode: string;
  Code: string;
  State: string;
  /** @format double */
  Status: number;
  CompanyForm: string;
  PlaceOfBusiness: string;
  CompanyName: string;
  ID: string;
}

export interface TestDataAddress {
  Coordinates: string;
  Code: string;
  EndDate: string;
  StartDate: string;
  ForeignCityAndCountry: string;
  ForeignCity: string;
  CountryCode: string;
  PostalDistrict: string;
  PostalCode: string;
  Address: string;
  /** @format double */
  Type: number;
  OwnerID: string;
  ID: string;
}

export type JwtTemplate = Record<string, any>;

export interface DisplayInfo {
  name: string;
  /** for example "fi-FI" or "en-US" */
  locale: string;
  logo?: {
    alt_text?: string;
    uri: string;
  };
  background_color?: string;
  text_color?: string;
  description?: string;
}

export interface SelectiveDisclosureInfo {
  _sd_discloseable?: boolean;
  _type?: "string" | "number" | "datetime" | "date" | "boolean" | "object" | "array";
  display?: DisplayInfo[];
  [key: string]: any;
}

export interface SdJwtTemplate {
  claims: Record<string, SelectiveDisclosureInfo>;
}

export interface VcJwtTemplate {
  credentialSubject: {
    id?: string;
    [key: string]: any;
  };
  [key: string]: any;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickTemplateEntityIdOrContentOrDisplayOrFormatOrNameOrTypeOrSchema {
  /**
   * Unique id of the credential (without urn:uuid:)
   * @format double
   */
  id: number;
  content: JwtTemplate | SdJwtTemplate | VcJwtTemplate;
  display?: DisplayInfo[];
  format: "vc+sd-jwt" | "jwt_vc_json" | "json";
  /** Template name (also Verifiable credential configuration name, for example PIDSdJwt, LPIDSdJwt, EUCCSdJwt or vReceiptVcJwt) */
  name: string;
  /** Type of the credential (same as vct in sd-jwt), for example PID or LPID */
  type: string;
  schema: Record<string, any>;
}

export type TemplateInfo = PickTemplateEntityIdOrContentOrDisplayOrFormatOrNameOrTypeOrSchema;

export interface CreateTemplateParams {
  /** Schema content or URI to schema */
  schema?: Record<string, any> | string;
  /** Content type depending on the given format */
  content?: SdJwtTemplate | VcJwtTemplate | JwtTemplate;
  /** Credential display info (used in openid-credential-issuer metadata) */
  display?: DisplayInfo[];
  /** For example PID */
  type: string;
  /** For example "vc+sd-jwt" */
  format: "vc+sd-jwt" | "jwt_vc_json";
  /** For example PIDSdJwt */
  name: string;
}

/** Make all properties in T optional */
export interface PartialTemplateEntity {
  /**
   * Unique id of the credential (without urn:uuid:)
   * @format double
   */
  id?: number;
  uuid?: string;
  /** Template name (also Verifiable credential configuration name, for example PIDSdJwt, LPIDSdJwt, EUCCSdJwt or vReceiptVcJwt) */
  name?: string;
  display?: DisplayInfo[];
  format?: "vc+sd-jwt" | "jwt_vc_json" | "json";
  /** Type of the credential (same as vct in sd-jwt), for example PID or LPID */
  type?: string;
  content?: JwtTemplate | SdJwtTemplate | VcJwtTemplate;
  schema?: Record<string, any>;
}

export type UpdateTemplateParams = PartialTemplateEntity;

export type JWT = string;

/** Make all properties in T readonly */
export type ReadonlyW3CCredential = object;

export interface Proof {
  type?: string;
  [key: string]: any;
}

/**
 * Represents a readonly representation of a verifiable object, including the {@link Proof}
 * property that can be used to verify it.
 */
export type VerifiableW3CCredential = ReadonlyW3CCredential & {
  proof: Proof;
};

/** A union type for both possible representations of a Credential (JWT and W3C standard) */
export type VerifiableCredential = JWT | VerifiableW3CCredential;

export type VerifiableCredential2 = VerifiableCredential & {
  /** @format date-time */
  validFrom: string;
  issuer: string;
};

export interface SchemaAndJsonLdInfo {
  jsonLd: Record<string, any>;
  jsonSchema: Record<string, any>;
}

export interface SuomiFiAuthorizationRole {
  name: string;
  identifier: string;
  complete: boolean;
  roles: string[];
}

export interface SessionInfo {
  displayName: string;
  givenNames: string;
  lastname: string;
  /** @format date-time */
  birthdate?: string;
  personId?: string;
  role: string;
  authorizationRoles: SuomiFiAuthorizationRole[];
}

export enum LogType {
  Information = "information",
  Warning = "warning",
  Error = "error",
  Debug = "debug",
  Fetch = "fetch",
}

export interface ExportedKeyPair {
  /**
   * JSON Web Key ({@link https://www.rfc-editor.org/rfc/rfc7517 JWK}). "RSA", "EC", "OKP", and "oct"
   * key types are supported.
   */
  publicKey: JWK;
  /**
   * JSON Web Key ({@link https://www.rfc-editor.org/rfc/rfc7517 JWK}). "RSA", "EC", "OKP", and "oct"
   * key types are supported.
   */
  privateKey: JWK;
}

/**
 * Wrapper type for relation type definitions in entities.
 * Used to circumvent ESM modules circular dependency issue caused by reflection metadata saving the type of the property.
 *
 * Usage example:
 */
export type RelationHolderEntity = HolderEntity;

export interface CredentialEntity {
  /** Unique id of the credential (without urn:uuid:) */
  id: string;
  /** verifiable credential type */
  credentialType: string;
  /** verifiable credential type */
  format: string;
  /** verifiable credential types */
  types?: string[];
  /** @format date-time */
  created: string;
  /** Issuer of the credential */
  issuer: string;
  verified?: boolean;
  /**
   * Credential is valid from
   * @format date-time
   */
  validFrom: string;
  /**
   * Credential is valid to
   * @format date-time
   */
  validTo?: string;
  /**
   * Credential data
   * In sd-jwt it contains the JWS+disclosures+keybinding in base64 format (TODO use binary?)
   */
  data: string;
  revoked: boolean;
  /**
   * Wrapper type for relation type definitions in entities.
   * Used to circumvent ESM modules circular dependency issue caused by reflection metadata saving the type of the property.
   *
   * Usage example:
   */
  holder?: RelationHolderEntity;
}

/**
 * Wrapper type for relation type definitions in entities.
 * Used to circumvent ESM modules circular dependency issue caused by reflection metadata saving the type of the property.
 *
 * Usage example:
 */
export type RelationCredentialEntityArray = CredentialEntity[];

export interface LogEntity {
  /** @format double */
  id: number;
  /** @format date-time */
  created: string;
  title: string;
  message: string;
  type: LogType;
  holder?: RelationHolderEntity | null;
  /** @format double */
  holderId?: number | null;
  entityType?: "holder" | "verifier" | "issuer";
  /** @format double */
  entityId?: number | null;
}

/**
 * Wrapper type for relation type definitions in entities.
 * Used to circumvent ESM modules circular dependency issue caused by reflection metadata saving the type of the property.
 *
 * Usage example:
 */
export type RelationLogEntityArray = LogEntity[];

export interface HolderUserEntity {
  /** @format double */
  id: number;
  publicKey: string;
  givenNames: string;
  familyName: string;
  /** Hash it before storing to database */
  password: string;
  phone: string;
  email: string;
  role: string;
  /** @format double */
  amountOfShare: number;
  signatoryRights: boolean;
  holder?: RelationHolderEntity | null;
}

/**
 * Wrapper type for relation type definitions in entities.
 * Used to circumvent ESM modules circular dependency issue caused by reflection metadata saving the type of the property.
 *
 * Usage example:
 */
export type RelationHolderUserEntityArray = HolderUserEntity[];

export interface HolderEntity {
  /** @format double */
  id: number;
  name: string;
  exportedKeyPairs: ExportedKeyPair[];
  /** Hash it before storing to database */
  password: string;
  /**
   * Wrapper type for relation type definitions in entities.
   * Used to circumvent ESM modules circular dependency issue caused by reflection metadata saving the type of the property.
   *
   * Usage example:
   */
  credentials: RelationCredentialEntityArray;
  /**
   * Wrapper type for relation type definitions in entities.
   * Used to circumvent ESM modules circular dependency issue caused by reflection metadata saving the type of the property.
   *
   * Usage example:
   */
  logs: RelationLogEntityArray;
  /**
   * Wrapper type for relation type definitions in entities.
   * Used to circumvent ESM modules circular dependency issue caused by reflection metadata saving the type of the property.
   *
   * Usage example:
   */
  users: RelationHolderUserEntityArray;
}

export interface IssuerInfo {
  logo?: string;
  api: string;
  domain: string;
  did: string;
  name: string;
  /** @format double */
  id: number;
}

/** Make all properties in T optional */
export interface PartialPickIssuerEntityDidOrDomainOrApiOrLogo {
  did?: string;
  domain?: string;
  api?: string;
  logo?: string;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickIssuerEntityName {
  name: string;
}

export type IssuerCreationParams = PartialPickIssuerEntityDidOrDomainOrApiOrLogo & PickIssuerEntityName;

/** Make all properties in T optional */
export interface PartialPickIssuerEntityDidOrDomainOrApiOrNameOrLogo {
  name?: string;
  did?: string;
  domain?: string;
  api?: string;
  logo?: string;
}

export type IssuerUpdateParams = PartialPickIssuerEntityDidOrDomainOrApiOrNameOrLogo;

export interface JwtVcIssuerMetadata {
  issuer: string;
  jwks_uri?: string;
  /** JSON Web Key Set */
  jwks?: JSONWebKeySet;
}

export interface CredentialDefinitionClaimDisplay {
  name: string;
  locale: string;
}

export type CredentialDefinitionClaims = Record<
  string,
  {
    display?: CredentialDefinitionClaimDisplay[];
  }
>;

export interface CredentialConfiguration {
  /** REQUIRED. A JSON string identifying the format of this Credential, i.e., jwt_vc_json or ldp_vc. Depending on the format value, the object contains further elements defining the type and (optionally) particular claims the Credential MAY contain and information about how to display the Credential. Appendix A contains Credential Format Profiles introduced by this specification. */
  format: string;
  /** OPTIONAL. A JSON string identifying the scope value that this Credential Issuer supports for this particular Credential. The value can be the same across multiple credential_configurations_supported objects. The Authorization Server MUST be able to uniquely identify the Credential Issuer based on the scope value. The Wallet can use this value in the Authorization Request as defined in Section 5.1.2. Scope values in this Credential Issuer metadata MAY duplicate those in the scopes_supported parameter of the Authorization Server. */
  scope?: string;
  /** OPTIONAL. Array of case sensitive strings that identify the representation of the cryptographic key material that the issued Credential is bound to, as defined in Section 7.1. Support for keys in JWK format [RFC7517] is indicated by the value jwk. Support for keys expressed as a COSE Key object [RFC8152] (for example, used in [ISO.18013-5]) is indicated by the value cose_key. When the Cryptographic Binding Method is a DID, valid values are a did: prefix followed by a method-name using a syntax as defined in Section 3.1 of [DID-Core], but without a :and method-specific-id. For example, support for the DID method with a method-name "example" would be represented by did:example. */
  cryptographic_binding_methods_supported?: string[];
  /** OPTIONAL. Array of case sensitive strings that identify the algorithms that the Issuer uses to sign the issued Credential. Algorithm names used are determined by the Credential format and are defined in Appendix A. */
  credential_signing_alg_values_supported?: string[];
  /** OPTIONAL. Object that describes specifics of the key proof(s) that the Credential Issuer supports. This object contains a list of name/value pairs, where each name is a unique identifier of the supported proof type(s). Valid values are defined in Section 7.2.1, other values MAY be used. This identifier is also used by the Wallet in the Credential Request as defined in Section 7.2. The value in the name/value pair is an object that contains metadata about the key proof and contains the following parameters defined by this specification: */
  proof_types_supported?: Record<
    string,
    {
      /** REQUIRED. Array of case sensitive strings that identify the algorithms that the Issuer supports for this proof type. The Wallet uses one of them to sign the proof. Algorithm names used are determined by the key proof type and are defined in Section 7.2.1. */
      proof_signing_alg_values_supported: string[];
    }
  >;
  /** OPTIONAL. Array of objects, where each object contains the display properties of the supported Credential for a certain language. Below is a non-exhaustive list of parameters that MAY be included. */
  display: {
    /** OPTIONAL. String value of a text color of the Credential represented as numerical color values defined in CSS Color Module Level 37 [CSS-Color]. */
    text_color?: string;
    /** OPTIONAL. Object with information about the background image of the Credential. At least the following parameter MUST be included: */
    background_image?: {
      /** REQUIRED. String value that contains a URI where the Wallet can obtain the background image of the Credential from the Credential Issuer. The Wallet needs to determine the scheme, since the URI value could use the https: scheme, the data: scheme, etc. */
      uri: string;
    };
    /** OPTIONAL. String value of a background color of the Credential represented as numerical color values defined in CSS Color Module Level 37 [CSS-Color]. */
    background_color?: string;
    /** OPTIONAL. String value of a description of the Credential. */
    description?: string;
    /** OPTIONAL. Object with information about the logo of the Credential. The following non-exhaustive set of parameters MAY be included: */
    logo?: {
      /** OPTIONAL. String value of the alternative text for the logo image. */
      alt_text?: string;
      /** REQUIRED. String value that contains a URI where the Wallet can obtain the logo of the Credential from the Credential Issuer. The Wallet needs to determine the scheme, since the URI value could use the https: scheme, the data: scheme, etc. */
      uri: string;
    };
    /** OPTIONAL. String value that identifies the language of this object represented as a language tag taken from values defined in BCP47 [RFC5646]. Multiple display objects MAY be included for separate languages. There MUST be only one object for each language identifier. */
    locale?: string;
    /** REQUIRED. String value of a display name for the Credential. */
    name: string;
  }[];
  credential_definition?: {
    credentialSubject?: Record<
      string,
      {
        display?: {
          locale?: string;
          name: string;
        }[];
      }
    >;
    type?: string[];
    claims?: Record<string, SelectiveDisclosureInfo>;
    vct?: string;
  };
  id?: string;
  types?: string[];
  vct?: string;
  cryptographic_suites_supported?: string[];
  claims?: CredentialDefinitionClaims;
}

export interface CredentialsSupported {
  format: string;
  id?: string;
  types?: string[];
  cryptographic_binding_methods_supported: string[];
  cryptographic_suites_supported?: string[];
  scope?: string;
  credential_definition?: {
    claims: CredentialDefinitionClaims;
    vct?: string;
    type?: string[];
  };
  display?: DisplayInfo[];
  vct?: string;
}

export interface OpenIdCredentialIssuerMetadata {
  /** REQUIRED. The Credential Issuer's identifier, as defined in Section 11.2.1. */
  credential_issuer: string;
  /** OPTIONAL. Array of strings, where each string is an identifier of the OAuth 2.0 Authorization Server (as defined in [RFC8414]) the Credential Issuer relies on for authorization. If this parameter is omitted, the entity providing the Credential Issuer is also acting as the Authorization Server, i.e., the Credential Issuer's identifier is used to obtain the Authorization Server metadata. The actual OAuth 2.0 Authorization Server metadata is obtained from the oauth-authorization-server well-known location as defined in Section 3 of [RFC8414]. When there are multiple entries in the array, the Wallet may be able to determine which Authorization Server to use by querying the metadata; for example, by examining the grant_types_supported values, the Wallet can filter the server to use based on the grant type it plans to use. When the Wallet is using authorization_server parameter in the Credential Offer as a hint to determine which Authorization Server to use out of multiple, the Wallet MUST NOT proceed with the flow if the authorization_server Credential Offer parameter value does not match any of the entries in the authorization_servers array. */
  authorization_servers: string[];
  /** REQUIRED. URL of the Credential Issuer's Credential Endpoint, as defined in Section 7.2. This URL MUST use the https scheme and MAY contain port, path, and query parameter components. */
  credential_endpoint: string;
  /** OPTIONAL. URL of the Credential Issuer's Batch Credential Endpoint, as defined in Section 8. This URL MUST use the https scheme and MAY contain port, path, and query parameter components. If omitted, the Credential Issuer does not support the Batch Credential Endpoint. */
  batch_credential_endoint?: string;
  /** OPTIONAL. URL of the Credential Issuer's Deferred Credential Endpoint, as defined in Section 9. This URL MUST use the https scheme and MAY contain port, path, and query parameter components. If omitted, the Credential Issuer does not support the Deferred Credential Endpoint. */
  deferred_credential_endpoint?: string;
  /** OPTIONAL. URL of the Credential Issuer's Notification Endpoint, as defined in Section 10. This URL MUST use the https scheme and MAY contain port, path, and query parameter components. If omitted, the Credential Issuer does not support the Notification Endpoint. */
  notification_endpoint?: string;
  /** OPTIONAL. Object containing information about whether the Credential Issuer supports encryption of the Credential and Batch Credential Response on top of TLS. */
  credential_response_encryption?: {
    /** REQUIRED. Boolean value specifying whether the Credential Issuer requires the additional encryption on top of TLS for the Credential Response. If the value is true, the Credential Issuer requires encryption for every Credential Response and therefore the Wallet MUST provide encryption keys in the Credential Request. If the value is false, the Wallet MAY chose whether it provides encryption keys or not. */
    encryption_required: boolean;
    enc_values_supported: string[];
    /** REQUIRED. Array containing a list of the JWE [RFC7516] encryption algorithms (enc values) [RFC7518] supported by the Credential and Batch Credential Endpoint to encode the Credential or Batch Credential Response in a JWT [RFC7519]. */
    enc_values: string[];
    alg_values_supported: string[];
    /** REQUIRED. Array containing a list of the JWE [RFC7516] encryption algorithms (alg values) [RFC7518] supported by the Credential and Batch Credential Endpoint to encode the Credential or Batch Credential Response in a JWT [RFC7519]. */
    alg_values: string[];
  };
  /** OPTIONAL. Boolean value specifying whether the Credential Issuer supports returning credential_identifiers parameter in the authorization_details Token Response parameter, with true indicating support. If omitted, the default value is false. */
  credential_identifiers_supported?: boolean;
  /** OPTIONAL. String that is a signed JWT. This JWT contains Credential Issuer metadata parameters as claims. The signed metadata MUST be secured using JSON Web Signature (JWS) [RFC7515] and MUST contain an iat (Issued At) claim, an iss (Issuer) claim denoting the party attesting to the claims in the signed metadata, and sub (Subject) claim matching the Credential Issuer identifier. If the Wallet supports signed metadata, metadata values conveyed in the signed JWT MUST take precedence over the corresponding values conveyed using plain JSON elements. If the Credential Issuer wants to enforce use of signed metadata, it omits the respective metadata parameters from the unsigned part of the Credential Issuer metadata. A signed_metadata metadata value MUST NOT appear as a claim in the JWT. The Wallet MUST establish trust in the signer of the metadata, and obtain the keys to validate the signature before processing the metadata. The concrete mechanism how to do that is out of scope of this specification and MAY be defined in the profiles of this specification. */
  signed_metadata?: string;
  /** OPTIONAL. Array of objects, where each object contains display properties of a Credential Issuer for a certain language. Below is a non-exhaustive list of valid parameters that MAY be included: */
  display?: {
    /** For example "Finland" */
    location?: string;
    description?: string;
    /** OPTIONAL. Object with information about the logo of the Credential Issuer. Below is a non-exhaustive list of parameters that MAY be included: */
    logo?: {
      /** OPTIONAL. String value of the alternative text for the logo image. */
      alt_text?: string;
      /** REQUIRED. String value that contains a URI where the Wallet can obtain the logo of the Credential Issuer. The Wallet needs to determine the scheme, since the URI value could use the https: scheme, the data: scheme, etc. */
      uri: string;
    };
    /** OPTIONAL. String value that identifies the language of this object represented as a language tag taken from values defined in BCP47 [RFC5646]. There MUST be only one object for each language identifier. */
    locale?: string;
    /** OPTIONAL. String value of a display name for the Credential Issuer. */
    name?: string;
  }[];
  /** REQUIRED. Object that describes specifics of the Credential that the Credential Issuer supports issuance of. This object contains a list of name/value pairs, where each name is a unique identifier of the supported Credential being described. This identifier is used in the Credential Offer as defined in Section 4.1.1 to communicate to the Wallet which Credential is being offered. The value is an object that contains metadata about a specific Credential and contains the following parameters defined by this specification: */
  credential_configurations_supported: Record<string, CredentialConfiguration>;
  authorization_endpoint?: string;
  pushed_authorization_request_endpoint?: string;
  token_endpoint?: string;
  jwks_uri?: string;
  authorization_server?: string;
  credentials_supported?: CredentialsSupported[] | Record<string, CredentialsSupported>;
  require_credential_response_encryption?: boolean;
  require_pushed_authorization_requests?: boolean;
}

export interface OpenIdIssuerMetaData {
  /**
   * REQUIRED.  The authorization server's issuer identifier, which is
   * a URL that uses the "https" scheme and has no query or fragment
   * components.  Authorization server metadata is published at a
   * location that is ".well-known" according to RFC 5785 [RFC5785]
   * derived from this issuer identifier, as described in Section 3.
   * The issuer identifier is used to prevent authorization server mix-
   * up attacks, as described in "OAuth 2.0 Mix-Up Mitigation"
   */
  issuer: string;
  /**
   * URL of the authorization server's authorization endpoint
   * [RFC6749].  This is REQUIRED unless no grant types are supported
   * that use the authorization endpoint.
   */
  authorization_endpoint?: string;
  /**
   * URL of the authorization server's token endpoint [RFC6749].  This
   * is REQUIRED unless only the implicit grant type is supported.
   */
  token_endpoint?: string;
  /**
   * OPTIONAL.  URL of the authorization server's JWK Set [JWK]
   * document.  The referenced document contains the signing key(s) the
   * client uses to validate signatures from the authorization server.
   * This URL MUST use the "https" scheme.  The JWK Set MAY also
   * contain the server's encryption key or keys, which are used by
   * clients to encrypt requests to the server.  When both signing and
   * encryption keys are made available, a "use" (public key use)
   * parameter value is REQUIRED for all keys in the referenced JWK Set
   * to indicate each key's intended usage.
   */
  jwks_uri?: string;
  /**
   * OPTIONAL.  URL of the authorization server's OAuth 2.0 Dynamic
   * Client Registration endpoint [RFC7591].
   */
  registration_endpoint?: string;
  /**
   * RECOMMENDED.  JSON array containing a list of the OAuth 2.0
   * [RFC6749] "scope" values that this authorization server supports.
   * Servers MAY choose not to advertise some supported scope values
   * even when this parameter is used.
   */
  scopes_supported?: string[];
  /**
   * REQUIRED.  JSON array containing a list of the OAuth 2.0
   * "response_type" values that this authorization server supports.
   * The array values used are the same as those used with the
   * "response_types" parameter defined by "OAuth 2.0 Dynamic Client
   * Registration Protocol" [RFC7591].
   */
  response_types_supported: string[];
  /**
   * OPTIONAL.  JSON array containing a list of the OAuth 2.0
   * "response_mode" values that this authorization server supports, as
   * specified in "OAuth 2.0 Multiple Response Type Encoding Practices"
   * [OAuth.Responses].  If omitted, the default is "["query",
   * "fragment"]".  The response mode value "form_post" is also defined
   * in "OAuth 2.0 Form Post Response Mode" [OAuth.Post].
   */
  response_modes_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of the OAuth 2.0 grant
   * type values that this authorization server supports.  The array
   * values used are the same as those used with the "grant_types"
   * parameter defined by "OAuth 2.0 Dynamic Client Registration
   * Protocol" [RFC7591].  If omitted, the default value is
   * "["authorization_code", "implicit"]".
   */
  grant_types_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of client authentication
   * methods supported by this token endpoint.  Client authentication
   * method values are used in the "token_endpoint_auth_method"
   * parameter defined in Section 2 of [RFC7591].  If omitted, the
   * default is "client_secret_basic" -- the HTTP Basic Authentication
   * Scheme specified in Section 2.3.1 of OAuth 2.0 [RFC6749].
   */
  token_endpoint_auth_methods_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of the JWS signing
   * algorithms ("alg" values) supported by the token endpoint for the
   * signature on the JWT [JWT] used to authenticate the client at the
   * token endpoint for the "private_key_jwt" and "client_secret_jwt"
   * authentication methods.  This metadata entry MUST be present if
   * either of these authentication methods are specified in the
   * "token_endpoint_auth_methods_supported" entry.  No default
   * algorithms are implied if this entry is omitted.  Servers SHOULD
   * support "RS256".  The value "none" MUST NOT be used.
   */
  token_endpoint_auth_signing_alg_values_supported?: string[];
  /**
   * OPTIONAL.  URL of a page containing human-readable information
   * that developers might want or need to know when using the
   * authorization server.  In particular, if the authorization server does not support Dynamic Client Registration, then information on
   * how to register clients needs to be provided in this documentation.
   */
  service_documentation?: string;
  /**
   * OPTIONAL.  Languages and scripts supported for the user interface,
   * represented as a JSON array of language tag values from BCP 47
   * [RFC5646].  If omitted, the set of supported languages and scripts
   * is unspecified.
   */
  ui_locales_supported?: string[];
  /**
   * OPTIONAL.  URL that the authorization server provides to the
   * person registering the client to read about the authorization
   * server's requirements on how the client can use the data provided
   * by the authorization server.  The registration process SHOULD
   * display this URL to the person registering the client if it is
   * given.  As described in Section 5, despite the identifier
   * "op_policy_uri" appearing to be OpenID-specific, its usage in this
   * specification is actually referring to a general OAuth 2.0 feature
   * that is not specific to OpenID Connect.
   */
  op_policy_uri?: string;
  /**
   * OPTIONAL.  URL that the authorization server provides to the
   * person registering the client to read about the authorization
   * server's terms of service.  The registration process SHOULD
   * display this URL to the person registering the client if it is
   * given.  As described in Section 5, despite the identifier
   * "op_tos_uri", appearing to be OpenID-specific, its usage in this
   * specification is actually referring to a general OAuth 2.0 feature
   * that is not specific to OpenID Connect.
   */
  op_tos_uri?: string;
  /**
   * OPTIONAL.  URL of the authorization server's OAuth 2.0 revocation
   * endpoint [RFC7009].
   */
  revocation_endpoint?: string;
  /**
   * OPTIONAL.  JSON array containing a list of client authentication
   * methods supported by this revocation endpoint.  The valid client
   * authentication method values are those registered in the IANA
   * "OAuth Token Endpoint Authentication Methods" registry
   * [IANA.OAuth.Parameters].  If omitted, the default is
   * "client_secret_basic" -- the HTTP Basic Authentication Scheme
   * specified in Section 2.3.1 of OAuth 2.0 [RFC6749].
   */
  revocation_endpoint_auth_methods_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of the JWS signing
   * algorithms ("alg" values) supported by the revocation endpoint for
   * the signature on the JWT [JWT] used to authenticate the client at  the revocation endpoint for the "private_key_jwt" and
   * "client_secret_jwt" authentication methods.  This metadata entry
   * MUST be present if either of these authentication methods are
   * specified in the "revocation_endpoint_auth_methods_supported"
   * entry.  No default algorithms are implied if this entry is
   * omitted.  The value "none" MUST NOT be used.
   */
  revocation_endpoint_auth_signing_alg_values_supported?: string[];
  /**
   * OPTIONAL.  URL of the authorization server's OAuth 2.0
   * introspection endpoint [RFC7662].
   */
  introspection_endpoint?: string;
  /**
   * OPTIONAL.  JSON array containing a list of client authentication
   * methods supported by this introspection endpoint.  The valid
   * client authentication method values are those registered in the
   * IANA "OAuth Token Endpoint Authentication Methods" registry
   * [IANA.OAuth.Parameters] or those registered in the IANA "OAuth
   * Access Token Types" registry [IANA.OAuth.Parameters].  (These
   * values are and will remain distinct, due to Section 7.2.)  If
   * omitted, the set of supported authentication methods MUST be
   * determined by other means.
   */
  introspection_endpoint_auth_methods_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of the JWS signing
   * algorithms ("alg" values) supported by the introspection endpoint
   * for the signature on the JWT [JWT] used to authenticate the client
   * at the introspection endpoint for the "private_key_jwt" and
   * "client_secret_jwt" authentication methods.  This metadata entry
   * MUST be present if either of these authentication methods are
   * specified in the "introspection_endpoint_auth_methods_supported"
   * entry.  No default algorithms are implied if this entry is
   * omitted.  The value "none" MUST NOT be used.
   */
  introspection_endpoint_auth_signing_alg_values_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of Proof Key for Code
   * Exchange (PKCE) [RFC7636] code challenge methods supported by this
   * authorization server.  Code challenge method values are used in
   * the "code_challenge_method" parameter defined in Section 4.3 of
   * [RFC7636].  The valid code challenge method values are those
   * registered in the IANA "PKCE Code Challenge Methods" registry
   * [IANA.OAuth.Parameters].  If omitted, the authorization server
   * does not support PKCE.
   */
  code_challenge_methods_supported?: string[];
  /** OPTIONAL. JSON array containing a list of the Authentication Context Class References that this OP supports. */
  acr_values_supported?: string[];
  /** REQUIRED. JSON array containing a list of the Subject Identifier types that this OP supports. Valid types include pairwise and public. */
  subject_types_supported: string[];
  /** REQUIRED. JSON array containing a list of the JWS signing algorithms (alg values) supported by the OP for the ID Token to encode the Claims in a JWT [JWT]. The algorithm RS256 MUST be included. The value none MAY be supported but MUST NOT be used unless the Response Type used returns no ID Token from the Authorization Endpoint (such as when using the Authorization Code Flow) */
  id_token_signing_alg_values_supported: string[];
  /** OPTIONAL. JSON array containing a list of the JWE encryption algorithms (alg values) supported by the OP for the ID Token to encode the Claims in a JWT [JWT]. */
  id_token_encryption_alg_values_supported?: string[];
  /** OPTIONAL. JSON array containing a list of the JWE encryption algorithms (enc values) supported by the OP for the ID Token to encode the Claims in a JWT [JWT] */
  id_token_encryption_enc_values_supported?: string[];
  /** OPTIONAL. JSON array containing a list of the JWS [JWS] signing algorithms (alg values) [JWA] supported by the UserInfo Endpoint to encode the Claims in a JWT [JWT]. The value none MAY be included. */
  userinfo_signing_alg_values_supported?: string[];
  /** OPTIONAL. JSON array containing a list of the JWE [JWE] encryption algorithms (alg values) [JWA] supported by the UserInfo Endpoint to encode the Claims in a JWT [JWT]. */
  userinfo_encryption_alg_values_supported?: string[];
  /** OPTIONAL. JSON array containing a list of the JWE encryption algorithms (enc values) [JWA] supported by the UserInfo Endpoint to encode the Claims in a JWT [JWT]. */
  userinfo_encryption_enc_values_supported?: string[];
  /** OPTIONAL. JSON array containing a list of the JWS signing algorithms (alg values) supported by the OP for Request Objects, which are described in Section 6.1 of OpenID Connect Core 1.0 [OpenID.Core]. These algorithms are used both when the Request Object is passed by value (using the request parameter) and when it is passed by reference (using the request_uri parameter). Servers SHOULD support none and RS256. */
  request_object_signing_alg_values_supported?: string[];
  /** OPTIONAL. JSON array containing a list of the JWE encryption algorithms (alg values) supported by the OP for Request Objects. These algorithms are used both when the Request Object is passed by value and when it is passed by reference. */
  request_object_encryption_alg_values_supported?: string[];
  /** OPTIONAL. JSON array containing a list of the JWE encryption algorithms (enc values) supported by the OP for Request Objects. These algorithms are used both when the Request Object is passed by value and when it is passed by reference. */
  request_object_encryption_enc_values_supported?: string[];
  /** OPTIONAL. JSON array containing a list of the display parameter values that the OpenID Provider supports. These values are described in Section 3.1.2.1 of OpenID Connect Core 1.0 [OpenID.Core]. */
  display_values_supported?: string[];
  /** OPTIONAL. JSON array containing a list of the Claim Types that the OpenID Provider supports. These Claim Types are described in Section 5.6 of OpenID Connect Core 1.0 [OpenID.Core]. Values defined by this specification are normal, aggregated, and distributed. If omitted, the implementation supports only normal Claims. */
  claim_types_supported?: string[];
  /** RECOMMENDED. JSON array containing a list of the Claim Names of the Claims that the OpenID Provider MAY be able to supply values for. Note that for privacy or other reasons, this might not be an exhaustive list. */
  claims_supported?: string[];
  /** OPTIONAL. Languages and scripts supported for values in Claims being returned, represented as a JSON array of BCP47 [RFC5646] language tag values. Not all languages and scripts are necessarily supported for all Claim values. */
  claims_locales_supported?: string[];
  /** OPTIONAL. Boolean value specifying whether the OP supports use of the request_uri parameter, with true indicating support. If omitted, the default value is true. */
  request_uri_parameter_supported?: boolean;
  /** OPTIONAL. Boolean value specifying whether the OP requires any request_uri values used to be pre-registered using the request_uris registration parameter. Pre-registration is REQUIRED when the value is true. If omitted, the default value is false. */
  require_request_uri_registration?: boolean;
  vp_formats_supported: {
    ldp_vc: {
      proof_types_supported: string[];
    };
    ldp_vp: {
      proof_types_supported: string[];
    };
    jwt_vc: {
      alg_values_supported: string[];
    };
    jwt_vp: {
      alg_values_supported: string[];
    };
  };
  subject_syntax_types_supported: null;
  subject_syntax_types_discriminations: null;
  subject_trust_frameworks_supported: null;
  id_token_types_supported: null;
  credentials_supported?: Record<string, CredentialConfiguration>;
  redirect_uris?: string[];
}

/** Authorization Server Metadata */
export interface OauthServerMetadata {
  /**
   * REQUIRED.  The authorization server's issuer identifier, which is
   * a URL that uses the "https" scheme and has no query or fragment
   * components.  Authorization server metadata is published at a
   * location that is ".well-known" according to RFC 5785 [RFC5785]
   * derived from this issuer identifier, as described in Section 3.
   * The issuer identifier is used to prevent authorization server mix-
   * up attacks, as described in "OAuth 2.0 Mix-Up Mitigation"
   */
  issuer: string;
  /**
   * URL of the authorization server's authorization endpoint
   * [RFC6749].  This is REQUIRED unless no grant types are supported
   * that use the authorization endpoint.
   */
  authorization_endpoint?: string;
  /**
   * URL of the authorization server's token endpoint [RFC6749].  This
   * is REQUIRED unless only the implicit grant type is supported.
   */
  token_endpoint?: string;
  /**
   * OPTIONAL.  URL of the authorization server's JWK Set [JWK]
   * document.  The referenced document contains the signing key(s) the
   * client uses to validate signatures from the authorization server.
   * This URL MUST use the "https" scheme.  The JWK Set MAY also
   * contain the server's encryption key or keys, which are used by
   * clients to encrypt requests to the server.  When both signing and
   * encryption keys are made available, a "use" (public key use)
   * parameter value is REQUIRED for all keys in the referenced JWK Set
   * to indicate each key's intended usage.
   */
  jwks_uri?: string;
  /**
   * OPTIONAL.  URL of the authorization server's OAuth 2.0 Dynamic
   * Client Registration endpoint [RFC7591].
   */
  registration_endpoint?: string;
  /**
   * RECOMMENDED.  JSON array containing a list of the OAuth 2.0
   * [RFC6749] "scope" values that this authorization server supports.
   * Servers MAY choose not to advertise some supported scope values
   * even when this parameter is used.
   */
  scopes_supported?: string[];
  /**
   * REQUIRED.  JSON array containing a list of the OAuth 2.0
   * "response_type" values that this authorization server supports.
   * The array values used are the same as those used with the
   * "response_types" parameter defined by "OAuth 2.0 Dynamic Client
   * Registration Protocol" [RFC7591].
   */
  response_types_supported: string[];
  /**
   * OPTIONAL.  JSON array containing a list of the OAuth 2.0
   * "response_mode" values that this authorization server supports, as
   * specified in "OAuth 2.0 Multiple Response Type Encoding Practices"
   * [OAuth.Responses].  If omitted, the default is "["query",
   * "fragment"]".  The response mode value "form_post" is also defined
   * in "OAuth 2.0 Form Post Response Mode" [OAuth.Post].
   */
  response_modes_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of the OAuth 2.0 grant
   * type values that this authorization server supports.  The array
   * values used are the same as those used with the "grant_types"
   * parameter defined by "OAuth 2.0 Dynamic Client Registration
   * Protocol" [RFC7591].  If omitted, the default value is
   * "["authorization_code", "implicit"]".
   */
  grant_types_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of client authentication
   * methods supported by this token endpoint.  Client authentication
   * method values are used in the "token_endpoint_auth_method"
   * parameter defined in Section 2 of [RFC7591].  If omitted, the
   * default is "client_secret_basic" -- the HTTP Basic Authentication
   * Scheme specified in Section 2.3.1 of OAuth 2.0 [RFC6749].
   */
  token_endpoint_auth_methods_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of the JWS signing
   * algorithms ("alg" values) supported by the token endpoint for the
   * signature on the JWT [JWT] used to authenticate the client at the
   * token endpoint for the "private_key_jwt" and "client_secret_jwt"
   * authentication methods.  This metadata entry MUST be present if
   * either of these authentication methods are specified in the
   * "token_endpoint_auth_methods_supported" entry.  No default
   * algorithms are implied if this entry is omitted.  Servers SHOULD
   * support "RS256".  The value "none" MUST NOT be used.
   */
  token_endpoint_auth_signing_alg_values_supported?: string[];
  /**
   * OPTIONAL.  URL of a page containing human-readable information
   * that developers might want or need to know when using the
   * authorization server.  In particular, if the authorization server does not support Dynamic Client Registration, then information on
   * how to register clients needs to be provided in this documentation.
   */
  service_documentation?: string;
  /**
   * OPTIONAL.  Languages and scripts supported for the user interface,
   * represented as a JSON array of language tag values from BCP 47
   * [RFC5646].  If omitted, the set of supported languages and scripts
   * is unspecified.
   */
  ui_locales_supported?: string[];
  /**
   * OPTIONAL.  URL that the authorization server provides to the
   * person registering the client to read about the authorization
   * server's requirements on how the client can use the data provided
   * by the authorization server.  The registration process SHOULD
   * display this URL to the person registering the client if it is
   * given.  As described in Section 5, despite the identifier
   * "op_policy_uri" appearing to be OpenID-specific, its usage in this
   * specification is actually referring to a general OAuth 2.0 feature
   * that is not specific to OpenID Connect.
   */
  op_policy_uri?: string;
  /**
   * OPTIONAL.  URL that the authorization server provides to the
   * person registering the client to read about the authorization
   * server's terms of service.  The registration process SHOULD
   * display this URL to the person registering the client if it is
   * given.  As described in Section 5, despite the identifier
   * "op_tos_uri", appearing to be OpenID-specific, its usage in this
   * specification is actually referring to a general OAuth 2.0 feature
   * that is not specific to OpenID Connect.
   */
  op_tos_uri?: string;
  /**
   * OPTIONAL.  URL of the authorization server's OAuth 2.0 revocation
   * endpoint [RFC7009].
   */
  revocation_endpoint?: string;
  /**
   * OPTIONAL.  JSON array containing a list of client authentication
   * methods supported by this revocation endpoint.  The valid client
   * authentication method values are those registered in the IANA
   * "OAuth Token Endpoint Authentication Methods" registry
   * [IANA.OAuth.Parameters].  If omitted, the default is
   * "client_secret_basic" -- the HTTP Basic Authentication Scheme
   * specified in Section 2.3.1 of OAuth 2.0 [RFC6749].
   */
  revocation_endpoint_auth_methods_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of the JWS signing
   * algorithms ("alg" values) supported by the revocation endpoint for
   * the signature on the JWT [JWT] used to authenticate the client at  the revocation endpoint for the "private_key_jwt" and
   * "client_secret_jwt" authentication methods.  This metadata entry
   * MUST be present if either of these authentication methods are
   * specified in the "revocation_endpoint_auth_methods_supported"
   * entry.  No default algorithms are implied if this entry is
   * omitted.  The value "none" MUST NOT be used.
   */
  revocation_endpoint_auth_signing_alg_values_supported?: string[];
  /**
   * OPTIONAL.  URL of the authorization server's OAuth 2.0
   * introspection endpoint [RFC7662].
   */
  introspection_endpoint?: string;
  /**
   * OPTIONAL.  JSON array containing a list of client authentication
   * methods supported by this introspection endpoint.  The valid
   * client authentication method values are those registered in the
   * IANA "OAuth Token Endpoint Authentication Methods" registry
   * [IANA.OAuth.Parameters] or those registered in the IANA "OAuth
   * Access Token Types" registry [IANA.OAuth.Parameters].  (These
   * values are and will remain distinct, due to Section 7.2.)  If
   * omitted, the set of supported authentication methods MUST be
   * determined by other means.
   */
  introspection_endpoint_auth_methods_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of the JWS signing
   * algorithms ("alg" values) supported by the introspection endpoint
   * for the signature on the JWT [JWT] used to authenticate the client
   * at the introspection endpoint for the "private_key_jwt" and
   * "client_secret_jwt" authentication methods.  This metadata entry
   * MUST be present if either of these authentication methods are
   * specified in the "introspection_endpoint_auth_methods_supported"
   * entry.  No default algorithms are implied if this entry is
   * omitted.  The value "none" MUST NOT be used.
   */
  introspection_endpoint_auth_signing_alg_values_supported?: string[];
  /**
   * OPTIONAL.  JSON array containing a list of Proof Key for Code
   * Exchange (PKCE) [RFC7636] code challenge methods supported by this
   * authorization server.  Code challenge method values are used in
   * the "code_challenge_method" parameter defined in Section 4.3 of
   * [RFC7636].  The valid code challenge method values are those
   * registered in the IANA "PKCE Code Challenge Methods" registry
   * [IANA.OAuth.Parameters].  If omitted, the authorization server
   * does not support PKCE.
   */
  code_challenge_methods_supported?: string[];
}

/** Construct a type with a set of properties K of type T */
export type RecordStringUnknown = Record<string, any>;

export interface Credential {
  format: string;
  types: string[];
  credential_definition?: {
    types: string[];
    "@context"?: string[];
  };
  /** Construct a type with a set of properties K of type T */
  trust_framework?: RecordStringUnknown;
}

export interface CredentialOffer {
  credential_issuer: string;
  credential_configurations?: string[];
  credential_configuration_ids?: string[];
  credential_definition?: {
    types: string[];
    "@context": string[];
  };
  credentials: (Credential | string)[];
  grants?: {
    "urn:ietf:params:oauth:grant-type:pre-authorized_code"?: {
      user_pin_required?: boolean;
      tx_code?: {
        description: string;
        input_mode: "numeric" | "text";
        /** @format double */
        length: number;
      };
      "pre-authorized_code": string;
    };
    authorization_code: {
      issuer_state: string;
    };
  };
}

export interface TokenEndpointResponse {
  /** REQUIRED when authorization_details parameter is used to request issuance of a certain Credential type as defined in Section 5.1.1. It MUST NOT be used otherwise. It is an array of objects, as defined in Section 7 of [RFC9396]. In addition to the parameters defined in Section 5.1.1, this specification defines the following parameter to be used with the authorization details type openid_credential in the Token Response: */
  authorization_details?: {
    /** TODO do not use this, use credential_identifiers */
    credential_identifier?: string;
    /** OPTIONAL. Array of strings, each uniquely identifying a Credential that can be issued using the Access Token returned in this response. Each of these Credentials corresponds to the same entry in the credential_configurations_supported Credential Issuer metadata but can contain different claim values or a different subset of claims within the claims set identified by that Credential type. This parameter can be used to simplify the Credential Request, as defined in Section 7.2, where the credential_identifier parameter replaces the format parameter and any other Credential format-specific parameters in the Credential Request. When received, the Wallet MUST use these values together with an Access Token in subsequent Credential Requests. */
    credential_identifiers?: string[];
    credential_configuration_id: string;
    type: "openid_credential";
  };
  /**
   * OPTIONAL. Number denoting the lifetime in seconds of the c_nonce.
   * @format double
   */
  c_nonce_expires_in?: number;
  token_type: "bearer";
  access_token: string;
  /** OPTIONAL. String containing a nonce to be used when creating a proof of possession of the key proof (see Section 7.2). When received, the Wallet MUST use this nonce value for its subsequent requests until the Credential Issuer provides a fresh nonce. */
  c_nonce?: string;
}

export interface TokenEndpointRequest {
  /** The code representing the authorization to obtain Credentials of a certain type. This parameter MUST be present if the grant_type is urn:ietf:params:oauth:grant-type:pre-authorized_code. */
  grant_type: "urn:ietf:params:oauth:grant-type:pre-authorized_code" | "authorization_code";
  /** OPTIONAL. String value containing a Transaction Code. This value MUST be present if a tx_code object was present in the Credential Offer (including if the object was empty). This parameter MUST only be used if the grant_type is urn:ietf:params:oauth:grant-type:pre-authorized_code. */
  tx_code?: string;
  code?: string;
  code_verifier?: string;
  client_id?: string;
  redirect_uri?: string;
  "pre-authorized_code"?: string;
  state?: string;
  user_pin?: string;
}

export interface CredentialEndpointResponse {
  acceptance_token?: string;
  c_nonce_expires_in?: number | string;
  c_nonce: string;
  format?: string;
  credential: string;
}

/**
 * The JWK representation of an ephemeral public key.
 * See https://www.rfc-editor.org/rfc/rfc7518.html#section-6
 */
export interface EphemeralPublicKey {
  kty?: string;
  crv?: string;
  x?: string;
  y?: string;
  n?: string;
  e?: string;
}

export interface RecipientHeader {
  alg?: string;
  iv?: string;
  tag?: string;
  /**
   * The JWK representation of an ephemeral public key.
   * See https://www.rfc-editor.org/rfc/rfc7518.html#section-6
   */
  epk?: EphemeralPublicKey;
  kid?: string;
  apv?: string;
  apu?: string;
}

export interface Recipient {
  header: RecipientHeader;
  encrypted_key: string;
}

export interface JWE {
  protected: string;
  iv: string;
  ciphertext: string;
  tag: string;
  aad?: string;
  recipients?: Recipient[];
}

export interface VctCredentialRequest {
  format?: string;
  vct?: string;
}

export interface CredentialEndpointRequest {
  format?: string;
  /** https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#name-proof-types */
  proof?: {
    jwt: string;
    proof_type: string;
  };
  proofs?: {
    jwt: string[];
    /**
     * jwt: A JWT [RFC7519] is used for proof of possession. When a proof_type parameter in a proof object is set to jwt, it MUST also contain a jwt parameter that includes a JWT as defined in Section 7.2.1.1. When a proofs object is using a jwt proof type, it MUST include a jwt parameter with its value being an array of JWTs, where each JWT is formed as defined in Section 7.2.1.1.
     * ldp_vp: A W3C Verifiable Presentation object signed using the Data Integrity Proof [VC_Data_Integrity] as defined in [VC_DATA_2.0] or [VC_DATA] is used for proof of possession. When a proof_type parameter in a proof object is set to ldp_vp, it MUST also contain an ldp_vp parameter that includes a W3C Verifiable Presentation defined in Section 7.2.1.2. When a proofs object is using a ldp_vp proof type, it MUST include an ldp_vp parameter with its value being an array of W3C Verifiable Presentations, where each of these W3C Verifiable Presentation is formed as defined in Section 7.2.1.2.
     */
    proof_type: string;
  };
  credential_identifier?: string[];
  types?: string[];
  credential_response_encryption?: {
    enc: JWE;
    alg: JWE;
    /**
     * JSON Web Key ({@link https://www.rfc-editor.org/rfc/rfc7517 JWK}). "RSA", "EC", "OKP", and "oct"
     * key types are supported.
     */
    jwk: JWK;
  };
  credential_definition?: {
    vct?: string;
    types?: string[];
    type?: string[];
    "@context"?: string[];
  };
  credentialRequest?: VctCredentialRequest[];
  [key: string]: any;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickHolderEntityNameOrPassword {
  name: string;
  /** Hash it before storing to database */
  password: string;
}

export type HolderCreationParams = PickHolderEntityNameOrPassword;

/** used by wallet UI to display credential information */
export interface CredentialInfo {
  id: string;
  /** @format date-time */
  created: string;
  types: string[];
  header?: Record<string, any>;
  subject: Record<string, any>;
  disclosures: Record<string, any>;
  issuer: string;
  jwtType: string;
  /** @format double */
  validFrom: number;
  /** @format double */
  validTo?: number;
  verified: boolean;
  revoked: boolean;
}

export interface InitiatePresentationPayload {
  presentationRequestURL: string;
  credentialsIdsForOffers: Record<string, string>;
}

export interface InitiateIdTokenPresentationPayload {
  openidURL: string;
}

export interface HolderUserPayload {
  /** Hash it before storing to database */
  password: string;
  /** @format double */
  amountOfShare: number;
  email: string;
  familyName: string;
  givenNames: string;
  publicKey: string;
  phone: string;
  role: string;
  signatoryRights: boolean;
  /** @format double */
  id: number;
}

export interface HolderUserCreatePayload {
  /** Hash it before storing to database */
  password: string;
  /** @format double */
  amountOfShare: number;
  email: string;
  familyName: string;
  givenNames: string;
  publicKey: string;
  phone: string;
  role: string;
  signatoryRights: boolean;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Ecosystem for Verifiable Credentials
 * @version 0.0.1
 * @license TODO LICENSE
 * @baseUrl /api
 * @contact API Contact <minisuomi@outlook.com> (https://minisuomi.fi)
 *
 * TODO write description here
 */
export class VCE_API<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  verifiers = {
    /**
     * @description Init test verifiers (not for production)
     *
     * @tags Verifiers API
     * @name InitVerifiers
     * @request GET:/verifiers/init
     * @secure
     */
    initVerifiers: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/verifiers/init`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Get id list of verifiers
     *
     * @tags Verifiers API
     * @name GetVerifierNames
     * @request GET:/verifiers
     */
    getVerifierNames: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/verifiers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new verifier
     *
     * @tags Verifiers API
     * @name CreateVerifier
     * @request POST:/verifiers
     * @secure
     */
    createVerifier: (data: VerifierCreationParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/verifiers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description OpenID federation metadata, used to verify that verifier is authorized verify specific kind credentials TODO is this needed?
     *
     * @tags Verifiers API
     * @name GetOpenIdFederation
     * @request GET:/verifiers/{verifierId}/.well-known/openid-federation
     */
    getOpenIdFederation: (verifierId: any, params: RequestParams = {}) =>
      this.request<OpenIdFederation, any>({
        path: `/verifiers/${verifierId}/.well-known/openid-federation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get did configuration file of the verifier
     *
     * @tags Verifiers API
     * @name GetDidConfigurationJson
     * @request GET:/verifiers/{verifierId}/.well-known/did-configuration.json
     */
    getDidConfigurationJson: (verifierId: string, params: RequestParams = {}) =>
      this.request<DidJson | IErrorResponse, any>({
        path: `/verifiers/${verifierId}/.well-known/did-configuration.json`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get did file of the verifier
     *
     * @tags Verifiers API
     * @name GetWellKnownDidJson
     * @request GET:/verifiers/{verifierId}/.well-known/did.json
     */
    getWellKnownDidJson: (verifierId: string, params: RequestParams = {}) =>
      this.request<DidJson | IErrorResponse, any>({
        path: `/verifiers/${verifierId}/.well-known/did.json`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get did file of the verifier
     *
     * @tags Verifiers API
     * @name GetDidJson
     * @request GET:/verifiers/{verifierId}/did.json
     */
    getDidJson: (verifierId: string, params: RequestParams = {}) =>
      this.request<DidJson | IErrorResponse, any>({
        path: `/verifiers/${verifierId}/did.json`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get jwks keys from the verifier
     *
     * @tags Verifiers API
     * @name GetJwks
     * @request GET:/verifiers/{verifierId}/jwks
     */
    getJwks: (verifierId: string, params: RequestParams = {}) =>
      this.request<
        {
          keys: JWK[];
        },
        any
      >({
        path: `/verifiers/${verifierId}/jwks`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Adds Json Web Key (JWK) to verifier and returns the public key Note: DO NOT USE THE GIVEN EXAMPLE KEY ON PRODUCTION!
     *
     * @tags Verifiers API
     * @name AddJwk
     * @request POST:/verifiers/{verifierId}/jwks
     * @secure
     */
    addJwk: (verifierId: string, data: JWKPair, params: RequestParams = {}) =>
      this.request<JWK, any>({
        path: `/verifiers/${verifierId}/jwks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes Json Web Key (JWK) from verifier
     *
     * @tags Verifiers API
     * @name RemoveJwk
     * @request DELETE:/verifiers/{verifierId}/jwks
     * @secure
     */
    removeJwk: (
      verifierId: string,
      data: {
        kid: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/verifiers/${verifierId}/jwks`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifiers API
     * @name GetJwks2
     * @request GET:/verifiers/{verifierId}/oid4vp/auth/jwks
     * @deprecated
     */
    getJwks2: (verifierId: string, params: RequestParams = {}) =>
      this.request<
        {
          keys: JWK[];
        },
        any
      >({
        path: `/verifiers/${verifierId}/oid4vp/auth/jwks`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Adds x.509 certificate to verifier
     *
     * @tags Verifiers API
     * @name AddX509
     * @request POST:/verifiers/{verifierId}/x509
     * @secure
     */
    addX509: (
      verifierId: any,
      data: {
        publickey: string;
        privatekey: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/verifiers/${verifierId}/x509`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Simple method to create presentation definition for presentation request, if you want to request multiple credentials, use the advanced function. Presentation contains multiple paths to required claims to support many type of credentials, like sd-jwt and w3c credentials
     *
     * @tags Verifiers API
     * @name CreatePresentationRequestSimple
     * @request GET:/verifiers/{verifierId}/createPresentationRequestSimple/{credentialType}
     */
    createPresentationRequestSimple: (
      verifierId: string,
      credentialType: string,
      query: {
        /** Randomly generated state which is used by the client during whole presentation process */
        state: string;
        filterType?: boolean;
        /** Comma splitted claims to request, for example "given_name,family_name". Note that it also supports value and type for example "age=1:number" or even "animal=/(dog|cat)/:string" */
        claimsCSV?: string;
        client_id_scheme?: "did" | "redirect_uri" | "x509_san_dns";
        pathType?: "vc+sd-jwt" | "jwt_vc_json";
      },
      params: RequestParams = {},
    ) =>
      this.request<InitiateTransactionRequest, any>({
        path: `/verifiers/${verifierId}/createPresentationRequestSimple/${credentialType}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Supports multiple credential requests at once
     *
     * @tags Verifiers API
     * @name CreatePresentationRequest
     * @request POST:/verifiers/{verifierId}/createPresentationRequest
     */
    createPresentationRequest: (
      verifierId: string,
      query: {
        state: string;
        client_id_scheme?: "did" | "redirect_uri" | "x509_san_dns";
        pathType?: "vc+sd-jwt" | "jwt_vc_json";
      },
      data: {
        claims: SimpleClaim[];
        credentialType: string;
      }[],
      params: RequestParams = {},
    ) =>
      this.request<InitiateTransactionRequest, any>({
        path: `/verifiers/${verifierId}/createPresentationRequest`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Initiates presentation request and return url (verifier started flow)
     *
     * @tags Verifiers API
     * @name InitiateTransaction
     * @request POST:/verifiers/{verifierId}/initiate-transaction
     */
    initiateTransaction: (verifierId: string, data: InitiateTransactionRequest, params: RequestParams = {}) =>
      this.request<IAuthorizeResponse, any>({
        path: `/verifiers/${verifierId}/initiate-transaction`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used by the holder to fetch presentation request by session id
     *
     * @tags Verifiers API
     * @name Jar
     * @request GET:/verifiers/{verifierId}/jar/{state}
     */
    jar: (verifierId: string, state: string, params: RequestParams = {}) =>
      this.request<JWT, any>({
        path: `/verifiers/${verifierId}/jar/${state}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifiers API
     * @name Auth2
     * @request POST:/verifiers/{verifierId}/oid4vp/auth
     */
    auth2: (
      verifierId: string,
      data: {
        state: string;
        presentation_submission: string;
        vp_token: string;
        response?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          error?: string;
          redirect_uri?: string;
        },
        any
      >({
        path: `/verifiers/${verifierId}/oid4vp/auth`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifiers API
     * @name Auth3
     * @request POST:/verifiers/{verifierId}/oid4vp/auth/{state2}
     */
    auth3: (
      verifierId: any,
      state2: string,
      data: {
        presentation_submission: string;
        vp_token: string;
        state?: string;
        response?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          error?: string;
          redirect_uri?: string;
        },
        any
      >({
        path: `/verifiers/${verifierId}/oid4vp/auth/${state2}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifiers API
     * @name ResponseCode
     * @request POST:/verifiers/{verifierId}/response-code/{state}
     */
    responseCode: (
      verifierId: string,
      state: string,
      data: {
        responseCode: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format double */
          status: number;
        },
        any
      >({
        path: `/verifiers/${verifierId}/response-code/${state}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Polls for the result of the presentation. Note that this function have inside polling too. It tries to poll 30 seconds, if not found it returns null. After that you can call the poll function again. Note that credential presentation request is valid only for 5 minutes.
     *
     * @tags Verifiers API
     * @name Poll
     * @request GET:/verifiers/{verifierId}/poll/{state}
     */
    poll: (verifierId: string, state: string, params: RequestParams = {}) =>
      this.request<SimplifiedPresentationPayload | null, any>({
        path: `/verifiers/${verifierId}/poll/${state}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifiers API
     * @name IsIssuerTrustedByFederation
     * @request GET:/verifiers/{verifierId}/verify-federation
     */
    isIssuerTrustedByFederation: (
      verifierId: string,
      query: {
        /** Issuer did:web or https address */
        issuer: string;
        /** Public key of the issuer */
        jwk: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          issuerTrustedByFederations: string[];
          verifierTrustedFederations: string[];
          isTrustedByVerifier: boolean;
        },
        any
      >({
        path: `/verifiers/${verifierId}/verify-federation`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  testdata = {
    /**
     * @description Import testiaineisto.fi company data packet This Test Data API uses minisuomi test database which is generated from old dvv test data, testiaineisto.fi have newer data. Use this function to import newer data in case of missing company roles for users, note that old data is not removed. Note, avoid loading all the companies, this method is not optimized for large amounts of data
     *
     * @tags Test Data API
     * @name PostCompanies
     * @request POST:/testdata/import/companies
     * @secure
     */
    postCompanies: (data: TestiaineistoCompanyData[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testdata/import/companies`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get persons by person id codes (hetu codes)
     *
     * @tags Test Data API
     * @name GetPersons
     * @request GET:/testdata/persons
     */
    getPersons: (
      query: {
        personCodesCSV: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TestDataPerson[], any>({
        path: `/testdata/persons`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Get person by the person id (hetu)
     *
     * @tags Test Data API
     * @name GetPerson
     * @request GET:/testdata/persons/{identificationCode}
     */
    getPerson: (identificationCode: string, params: RequestParams = {}) =>
      this.request<TestDataPerson | null, any>({
        path: `/testdata/persons/${identificationCode}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get companies and roles for the given user
     *
     * @tags Test Data API
     * @name GetPersonCompanyRoles
     * @request GET:/testdata/persons/{identificationCode}/companyRoles
     */
    getPersonCompanyRoles: (identificationCode: string, params: RequestParams = {}) =>
      this.request<TestDataCompanyRole[], any>({
        path: `/testdata/persons/${identificationCode}/companyRoles`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get legal persons (test data)
     *
     * @tags Test Data API
     * @name GetCompanies
     * @request GET:/testdata/companies
     */
    getCompanies: (
      query: {
        /** Comma seperated company codes */
        companyIDsCSV: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TestDataCompany[], any>({
        path: `/testdata/companies`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Test Data API
     * @name GetCompany
     * @request GET:/testdata/companies/{companyCode}
     */
    getCompany: (companyCode: string, params: RequestParams = {}) =>
      this.request<TestDataCompany | null, any>({
        path: `/testdata/companies/${companyCode}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get addresses
     *
     * @tags Test Data API
     * @name GetAddresses
     * @request GET:/testdata/addresses
     */
    getAddresses: (
      query: {
        /** Comma seperated list of ownerIDs */
        ownerIDsCSV: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TestDataAddress[], any>({
        path: `/testdata/addresses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Get role list of legal presentatives for the legal person
     *
     * @tags Test Data API
     * @name GetCompanyRoles
     * @request GET:/testdata/companies/{companyCode}/companyRoles
     */
    getCompanyRoles: (companyCode: string, params: RequestParams = {}) =>
      this.request<TestDataCompanyRole[], any>({
        path: `/testdata/companies/${companyCode}/companyRoles`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  templates = {
    /**
     * @description This will create SdJwt templates for PID, LPID, EUCC and POA credentials if those does not exists yet in the system. It also creates VcJwt templates for poaVcJwt, eKuittiVcJwt and vReceiptVcJwt. Note it requires templates_api_key to call the function.
     *
     * @tags Templates API
     * @name InitTemplates
     * @request GET:/templates/init
     * @secure
     */
    initTemplates: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/templates/init`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Get all templates
     *
     * @tags Templates API
     * @name GetTemplates
     * @request GET:/templates
     */
    getTemplates: (params: RequestParams = {}) =>
      this.request<TemplateInfo[], any>({
        path: `/templates`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a template with given information. Templates are used to generate credential metadata for the issuers. SD-JWT credentials needs to have claims information. VC-JWT credentials may have schema information, which can be used to verify content of the credential. Schema can be used to verify but also mock the credential data. Display information is used to tell wallets how the fields are named. (minisuomi-wallet UI does not use display information yet)
     *
     * @tags Templates API
     * @name CreateTemplate
     * @request POST:/templates
     * @secure
     */
    createTemplate: (data: CreateTemplateParams, params: RequestParams = {}) =>
      this.request<TemplateInfo, any>({
        path: `/templates`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all names of credential templates
     *
     * @tags Templates API
     * @name GetTemplateNames
     * @request GET:/templates/names
     */
    getTemplateNames: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/templates/names`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get credential template by name of credential type
     *
     * @tags Templates API
     * @name GetTemplateInfo
     * @request GET:/templates/template/{templateId}
     */
    getTemplateInfo: (templateId: any, params: RequestParams = {}) =>
      this.request<TemplateInfo | null, any>({
        path: `/templates/template/${templateId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Updates template with given information
     *
     * @tags Templates API
     * @name UpdateTemplate
     * @request PATCH:/templates/template/{templateId}
     * @secure
     */
    updateTemplate: (templateId: any, data: UpdateTemplateParams, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/templates/template/${templateId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete template with given information
     *
     * @tags Templates API
     * @name DeleteTemplate
     * @request DELETE:/templates/template/{templateId}
     * @secure
     */
    deleteTemplate: (templateId: any, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/templates/template/${templateId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get credential template by name of credential type
     *
     * @tags Templates API
     * @name GetTemplateSchema
     * @request GET:/templates/template/{templateId}/schema.json
     */
    getTemplateSchema: (templateId: any, params: RequestParams = {}) =>
      this.request<JwtTemplate, any>({
        path: `/templates/template/${templateId}/schema.json`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Templates API
     * @name GenerateCredentialTemplate
     * @request GET:/templates/template/{credentialType}/generate
     */
    generateCredentialTemplate: (
      credentialType: string,
      query: {
        version: string;
        lang: string;
        yModelName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<VerifiableCredential2, any>({
        path: `/templates/template/${credentialType}/generate`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Templates API
     * @name GenerateCredentialTemplateByJsonLdAndSchema
     * @request POST:/templates/template/{credentialType}/generate
     */
    generateCredentialTemplateByJsonLdAndSchema: (
      credentialType: string,
      query: {
        version: string;
        lang: string;
        yModelName?: string;
      },
      data: SchemaAndJsonLdInfo,
      params: RequestParams = {},
    ) =>
      this.request<VerifiableCredential2, any>({
        path: `/templates/template/${credentialType}/generate`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete credential template
     *
     * @tags Templates API
     * @name DeleteCredentialTemplate
     * @request DELETE:/templates/old/{id}
     * @secure
     */
    deleteCredentialTemplate: (id: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/templates/old/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  suomifi = {
    /**
     * No description
     *
     * @tags Suomi.fi authorization API
     * @name TriggerYpa
     * @request GET:/suomifi/ypa/trigger
     * @secure
     */
    triggerYpa: (
      query: {
        returnUrl: string;
        lang: "fi" | "en";
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          redirectUrl: string;
        },
        any
      >({
        path: `/suomifi/ypa/trigger`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Suomi.fi authorization API
     * @name YpaCallback
     * @request GET:/suomifi/ypa/callback
     */
    ypaCallback: (
      query?: {
        error?: string;
        code?: string;
        state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/suomifi/ypa/callback`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  sessions = {
    /**
     * @description Deletes all sessions from database. After this access tokens cannot be anymore refreshed, also other methods which uses sessions will not work - until user re-authenticates/login
     *
     * @tags Sessions API
     * @name ClearSessions
     * @request DELETE:/sessions
     * @secure
     */
    clearSessions: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sessions`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Get authenticated user information. Note you must be authenticated in, or it throws error
     *
     * @tags Sessions API
     * @name GetSessionInfo
     * @request GET:/sessions
     * @secure
     */
    getSessionInfo: (params: RequestParams = {}) =>
      this.request<SessionInfo, any>({
        path: `/sessions`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Get login url for suomi.fi authentication. Call this after user has clicked the login button and redirect browser to redriect_uri to finalize authentication Return refresh token and access token
     *
     * @tags Sessions API
     * @name GetAuthorizeUrl
     * @request GET:/sessions/getAuthorizeUrl
     */
    getAuthorizeUrl: (
      query: {
        relayState: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          redirect_uri: string;
        },
        any
      >({
        path: `/sessions/getAuthorizeUrl`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description Get logout url to logout from suomi.fi services. Call this after user has clicked the logout button and redirect browser to the returned address to finalize logout session.
     *
     * @tags Sessions API
     * @name GetLogoutUrl
     * @request GET:/sessions/logout
     * @secure
     */
    getLogoutUrl: (
      query: {
        /** URL where to return after logout. */
        returnUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          redirectUrl: string;
        },
        any
      >({
        path: `/sessions/logout`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions API
     * @name RefreshTokens
     * @request POST:/sessions/refresh
     */
    refreshTokens: (params: RequestParams = {}) =>
      this.request<
        {
          csrf: string;
        },
        any
      >({
        path: `/sessions/refresh`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions API
     * @name SamlAssertionConsumerService
     * @request POST:/sessions/SAML2/ACS/POST
     */
    samlAssertionConsumerService: (
      data: {
        SAMLResponse: string;
        RelayState: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/sessions/SAML2/ACS/POST`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions API
     * @name SamlSingleLogoutPost
     * @request POST:/sessions/SAML2/SLO/POST
     */
    samlSingleLogoutPost: (
      data: {
        SAMLResponse: string;
        RelayState: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/sessions/SAML2/SLO/POST`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions API
     * @name SamlSingleLogoutRedirectGet
     * @request GET:/sessions/SAML2/SLO/Redirect
     */
    samlSingleLogoutRedirectGet: (
      query: {
        SAMLResponse: string;
        RelayState: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/sessions/SAML2/SLO/Redirect`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions API
     * @name SamlSingleLogoutRedirectPost
     * @request POST:/sessions/SAML2/SLO/Redirect
     */
    samlSingleLogoutRedirectPost: (
      data: {
        SAMLResponse: string;
        RelayState: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/sessions/SAML2/SLO/Redirect`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
  };
  loggers = {
    /**
     * @description Gets logs from holder, verifiers, issuers, and also from the system. DO NOT ALLOW TO GET LOGS IN PRODUCTION WITHOUT API KEY.
     *
     * @tags Loggers API
     * @name GetLogs
     * @request GET:/loggers
     */
    getLogs: (
      query?: {
        /**
         * Comma seperated list of log types. Use "error", if you want to get only error logs.
         * @default "information,warning,error,debug,fetch"
         */
        typesCSV?: string;
        entityType?: "holder" | "issuer" | "verifier";
        /** @format double */
        entityId?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
        /** @format double */
        skip?: number;
        /** @format double */
        take?: number;
        /** @default false */
        count?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format double */
          totalCount: number;
          logs: LogEntity[];
        },
        any
      >({
        path: `/loggers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes all logs of the entity from database
     *
     * @tags Loggers API
     * @name ClearLogs
     * @request DELETE:/loggers
     * @secure
     */
    clearLogs: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/loggers`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Loggers API
     * @name DeleteLogById
     * @request DELETE:/loggers/{id}
     * @secure
     */
    deleteLogById: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/loggers/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  keys = {
    /**
     * No description
     *
     * @tags Keys API
     * @name GetKeyByAlgorithm
     * @request GET:/keys/{alg}
     */
    getKeyByAlgorithm: (alg: "EdDSA" | "ES521" | "ES384" | "ES256", params: RequestParams = {}) =>
      this.request<JWKPair, any>({
        path: `/keys/${alg}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  issuers = {
    /**
     * @description Init test issuers (not for production)
     *
     * @tags Issuers API
     * @name InitIssuers
     * @request GET:/issuers/init
     * @secure
     */
    initIssuers: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/issuers/init`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Get names of all issuers
     *
     * @tags Issuers API
     * @name GetIssuers
     * @request GET:/issuers
     */
    getIssuers: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/issuers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Creates an issuer (you need to use issuers_api_key to create new issuer) NOTE at the moment issuer does not use any api-key or password, anyone can use it to issue credentials (will be implemented later)
     *
     * @tags Issuers API
     * @name CreateIssuer
     * @request POST:/issuers
     * @secure
     */
    createIssuer: (data: IssuerCreationParams, params: RequestParams = {}) =>
      this.request<IssuerInfo, any>({
        path: `/issuers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get basic information of the issuer
     *
     * @tags Issuers API
     * @name GetIssuer
     * @request GET:/issuers/{issuerId}
     */
    getIssuer: (issuerId: any, params: RequestParams = {}) =>
      this.request<IssuerInfo, any>({
        path: `/issuers/${issuerId}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Updates issuer values
     *
     * @tags Issuers API
     * @name UpdateIssuer
     * @request PATCH:/issuers/{issuerId}
     * @secure
     */
    updateIssuer: (issuerId: any, data: IssuerUpdateParams, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/issuers/${issuerId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes issuer, note that you need to use issuer_api_key
     *
     * @tags Issuers API
     * @name DeleteIssuer
     * @request DELETE:/issuers/{issuerId}
     * @secure
     */
    deleteIssuer: (issuerId: any, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/issuers/${issuerId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description List templates which has been added to the issuer
     *
     * @tags Issuers API
     * @name GetTemplates
     * @request GET:/issuers/{issuerId}/templates
     */
    getTemplates: (issuerId: any, params: RequestParams = {}) =>
      this.request<TemplateInfo[], any>({
        path: `/issuers/${issuerId}/templates`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Add template to issuer. Issuer uses template information to tell which credential types it supports, but also to generate the credentials to issue those.
     *
     * @tags Issuers API
     * @name AddTemplate
     * @request POST:/issuers/{issuerId}/templates
     * @secure
     */
    addTemplate: (
      issuerId: any,
      data: {
        templateId: number | string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/issuers/${issuerId}/templates`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Remove template from issuer. (not tested, might work) Issuer uses template information to tell which credential types it supports, but also to generate the credentials to issue those.
     *
     * @tags Issuers API
     * @name RemoveTemplate
     * @request DELETE:/issuers/{issuerId}/templates
     * @secure
     */
    removeTemplate: (
      issuerId: any,
      data: {
        templateId: number | string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/issuers/${issuerId}/templates`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get jwks keys from the issuer
     *
     * @tags Issuers API
     * @name GetJwks
     * @request GET:/issuers/{issuerId}/jwks
     */
    getJwks: (issuerId: any, params: RequestParams = {}) =>
      this.request<
        {
          keys: JWK[];
        },
        any
      >({
        path: `/issuers/${issuerId}/jwks`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Adds Json Web Key (JWK) to issuer and returns the public key Note: DO NOT USE THE GIVEN EXAMPLE KEY ON PRODUCTION!
     *
     * @tags Issuers API
     * @name AddJwk
     * @request POST:/issuers/{issuerId}/jwks
     * @secure
     */
    addJwk: (issuerId: any, data: JWKPair, params: RequestParams = {}) =>
      this.request<JWK, any>({
        path: `/issuers/${issuerId}/jwks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes Json Web Key (JWK) from issuer
     *
     * @tags Issuers API
     * @name RemoveJwk
     * @request DELETE:/issuers/{issuerId}/jwks
     * @secure
     */
    removeJwk: (
      issuerId: any,
      data: {
        kid: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/issuers/${issuerId}/jwks`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds x.509 certificate to issuer
     *
     * @tags Issuers API
     * @name AddX509
     * @request POST:/issuers/{issuerId}/x509
     * @secure
     */
    addX509: (
      issuerId: any,
      data: {
        publickey: string;
        privatekey: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/issuers/${issuerId}/x509`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization endpoint for issuer
     *
     * @tags Issuers API
     * @name GetAuthorize
     * @request GET:/issuers/{issuerId}/oidc/authorize
     */
    getAuthorize: (
      issuerId: any,
      query?: {
        state?: string;
        code_challenge?: string;
        /** The additional parameter issuer_state is added to convey state in the context of processing an issuer-initiated Credential Offer */
        issuer_state?: string;
        nonce?: string;
        /** Additional parameters wallet_issuer is added to enable the Credential Issuer to request Verifiable Presentations from the calling Wallet during Authorization Request processing. */
        wallet_issuer?: string;
        /** Additional parameters user_hint is added to enable the Credential Issuer to request Verifiable Presentations from the calling Wallet during Authorization Request processing. */
        user_hint?: string;
        redirect_uri?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/issuers/${issuerId}/oidc/authorize`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers API
     * @name GetAuthorizeCallback
     * @request GET:/issuers/{issuerId}/oidc/authorize/cb
     * @secure
     */
    getAuthorizeCallback: (
      issuerId: any,
      query?: {
        redirect_uri?: string;
        issuer_state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/issuers/${issuerId}/oidc/authorize/cb`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers API
     * @name GetAuthorizeCallback2
     * @request GET:/issuers/{issuerId}/oidc/authorize/cb2
     * @secure
     */
    getAuthorizeCallback2: (
      issuerId: any,
      query?: {
        redirect_uri?: string;
        issuer_state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/issuers/${issuerId}/oidc/authorize/cb2`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description OpenID federation metadata, used to verify that issuer is authorized issue specific kind credentials
     *
     * @tags Issuers API
     * @name GetOpenIdFederation
     * @request GET:/issuers/{issuerId}/.well-known/openid-federation
     */
    getOpenIdFederation: (issuerId: any, params: RequestParams = {}) =>
      this.request<JWT, any>({
        path: `/issuers/${issuerId}/.well-known/openid-federation`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Note this also works from the domain root /.well-known/jwt-vc-issuer/issuers/:issuerId
     *
     * @tags Issuers API
     * @name GetWellKnownJwtVcIssuer
     * @request GET:/issuers/{issuerId}/.well-known/jwt-vc-issuer
     */
    getWellKnownJwtVcIssuer: (issuerId: any, params: RequestParams = {}) =>
      this.request<JwtVcIssuerMetadata, any>({
        path: `/issuers/${issuerId}/.well-known/jwt-vc-issuer`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Holder uses this function to resolve issuer's metadata.
     *
     * @tags Issuers API
     * @name GetCredentialIssuer
     * @request GET:/issuers/{issuerId}/.well-known/openid-credential-issuer
     */
    getCredentialIssuer: (issuerId: any, params: RequestParams = {}) =>
      this.request<OpenIdCredentialIssuerMetadata, any>({
        path: `/issuers/${issuerId}/.well-known/openid-credential-issuer`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers API
     * @name GetWellKnownOpenIdConfiguration
     * @request GET:/issuers/{issuerId}/.well-known/openid-configuration
     */
    getWellKnownOpenIdConfiguration: (issuerId: any, params: RequestParams = {}) =>
      this.request<OpenIdIssuerMetaData, any>({
        path: `/issuers/${issuerId}/.well-known/openid-configuration`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers API
     * @name GetWellKnownOauthAuthorizationServer
     * @request GET:/issuers/{issuerId}/.well-known/oauth-authorization-server
     */
    getWellKnownOauthAuthorizationServer: (issuerId: any, params: RequestParams = {}) =>
      this.request<OauthServerMetadata, any>({
        path: `/issuers/${issuerId}/.well-known/oauth-authorization-server`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Returns .well-known/did.json for the issuer
     *
     * @tags Issuers API
     * @name GetWellKnownDidJson
     * @request GET:/issuers/{issuerId}/.well-known/did.json
     */
    getWellKnownDidJson: (issuerId: any, params: RequestParams = {}) =>
      this.request<DidJson | IErrorResponse, any>({
        path: `/issuers/${issuerId}/.well-known/did.json`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Returns did.json for the issuer without .well-known path
     *
     * @tags Issuers API
     * @name GetDidJson
     * @request GET:/issuers/{issuerId}/did.json
     */
    getDidJson: (issuerId: any, params: RequestParams = {}) =>
      this.request<DidJson | IErrorResponse, any>({
        path: `/issuers/${issuerId}/did.json`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description This can be used to create credential offer. Note, it is using pre-authorized mode. TODO Implement authorization to prevent anyone calling this to issue credentials.
     *
     * @tags Issuers API
     * @name Issue
     * @request POST:/issuers/{issuerId}/openid4vci/issue/{credentialConfiguration}
     */
    issue: (
      issuerId: string,
      credentialConfiguration: string,
      data: JwtTemplate,
      query?: {
        /** @default true */
        useCredentialOfferUri?: boolean;
        /**
         * Credential is valid from (default value now)
         * @format date-time
         * @default "2025-01-22T13:42:32.769Z"
         */
        validFrom?: string;
        /**
         * Credential is valid to (optional, without value crdential does not expire)
         * @format date-time
         */
        validTo?: string;
        /** PIN code to request from the holder before issuing the credential */
        txCode?: string;
        /** KID of the key to use when issuing, leave empty to use the default key */
        kid?: string;
        overrideType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JWT, any>({
        path: `/issuers/${issuerId}/openid4vci/issue/${credentialConfiguration}`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This is used to trigger credential issuer to send credential to wallet (issuer initiated flow)
     *
     * @tags Issuers API
     * @name InitiateCredentialOffer
     * @request GET:/issuers/{issuerId}/initiate/credential_offer
     */
    initiateCredentialOffer: (
      issuerId: any,
      query: {
        /** credential endpoint or did:web address of the wallet */
        walletAddress: string;
        /** Issuer state used to find the credential offer */
        issuer_state: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JWT, any>({
        path: `/issuers/${issuerId}/initiate/credential_offer`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers API
     * @name CredentialOffer
     * @request GET:/issuers/{issuerId}/openid4vc/credential_offer
     */
    credentialOffer: (
      issuerId: any,
      query: {
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CredentialOffer, any>({
        path: `/issuers/${issuerId}/openid4vc/credential_offer`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers API
     * @name IssueUri
     * @request GET:/issuers/{issuerId}/openid4vc/credential_offer/{state}
     */
    issueUri: (issuerId: any, state: string, params: RequestParams = {}) =>
      this.request<CredentialOffer, any>({
        path: `/issuers/${issuerId}/openid4vc/credential_offer/${state}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Token endpoint for issuer
     *
     * @tags Issuers API
     * @name TokenEndpoint
     * @request POST:/issuers/{issuerId}/token
     */
    tokenEndpoint: (issuerId: string, data: TokenEndpointRequest, params: RequestParams = {}) =>
      this.request<TokenEndpointResponse, any>({
        path: `/issuers/${issuerId}/token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Not implemented yet!
     *
     * @tags Issuers API
     * @name DeferredCredentialEndPoint
     * @request POST:/issuers/{issuerId}/deferred
     */
    deferredCredentialEndPoint: (issuerId: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/issuers/${issuerId}/deferred`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Credential end point of the issuer, see https://openid.net/specs/openid-4-verifiable-credential-issuance-1_0.html#section-8
     *
     * @tags Issuers API
     * @name CredentialEndPoint
     * @request POST:/issuers/{issuerId}/credential
     */
    credentialEndPoint: (issuerId: string, data: CredentialEndpointRequest, params: RequestParams = {}) =>
      this.request<CredentialEndpointResponse | string, any>({
        path: `/issuers/${issuerId}/credential`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  holders = {
    /**
     * @description Used to login with minisuomi test data without suomi.fi connection (note that minisuomi uses about the same test data as suomi.fi) NOTE: this login method does not need any password, do not enable this on production
     *
     * @tags Holders API
     * @name LoginWithTestData
     * @request POST:/holders/login
     */
    loginWithTestData: (
      data: {
        personId: string;
        companyCode: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          csrfToken: string;
        },
        any
      >({
        path: `/holders/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description After successful login it returns csrf token (csrf protection is not implemented yet)
     *
     * @tags Holders API
     * @name Login
     * @request POST:/holders/{holderId}/login
     */
    login: (holderId: string, data: JWT, params: RequestParams = {}) =>
      this.request<
        {
          csrfToken: string;
        },
        any
      >({
        path: `/holders/${holderId}/login`,
        method: "POST",
        body: data,
        type: ContentType.Text,
        ...params,
      }),

    /**
     * @description This can be used to create credential offer. Note, it is using pre-authorized mode. TODO Implement authorization to prevent anyone calling this to issue credentials.
     *
     * @tags Holders API
     * @name Issue
     * @request POST:/holders/openid4vci/issue/{credentialConfiguration}
     * @secure
     */
    issue: (
      credentialConfiguration: string,
      data: JwtTemplate,
      query?: {
        pinCode?: string;
        /** @default true */
        useCredentialOfferUri?: boolean;
        /**
         * Credential is valid from (default value now)
         * @format date-time
         * @default "2025-01-22T13:42:32.777Z"
         */
        validFrom?: string;
        /**
         * Credential is valid to (optional, without value crdential does not expire)
         * @format date-time
         */
        validTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JWT, any>({
        path: `/holders/openid4vci/issue/${credentialConfiguration}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description You can create new wallet with this method, just give the name. You need to use holders_api_key.
     *
     * @tags Holders API
     * @name CreateHolder
     * @request POST:/holders
     * @secure
     */
    createHolder: (data: HolderCreationParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/holders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes holder from the database. After this you cannot use holder anymore and all the data is lost. You need to use holders_api_key.
     *
     * @tags Holders API
     * @name DeleteHolderAdmin
     * @request DELETE:/holders/holder/{holderId}
     * @secure
     */
    deleteHolderAdmin: (holderId: any, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/holders/holder/${holderId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds Json Web Key (JWK) to holder and returns the public key Note: DO NOT USE THE GIVEN EXAMPLE KEY ON PRODUCTION!
     *
     * @tags Holders API
     * @name AddJwk
     * @request POST:/holders/holder/{holderId}/jwks
     * @secure
     */
    addJwk: (holderId: any, data: JWKPair, params: RequestParams = {}) =>
      this.request<JWK, any>({
        path: `/holders/holder/${holderId}/jwks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes Json Web Key (JWK) from holder by kid
     *
     * @tags Holders API
     * @name RemoveJwk
     * @request DELETE:/holders/holder/{holderId}/jwks
     * @secure
     */
    removeJwk: (
      holderId: string,
      query: {
        /** Id of the JWK to remove */
        kid: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/holders/holder/${holderId}/jwks`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates own Json Web Key (JWK) private public key pair
     *
     * @tags Holders API
     * @name AddOwnJwk
     * @request POST:/holders/jwks
     * @secure
     */
    addOwnJwk: (data: JWKPair, params: RequestParams = {}) =>
      this.request<JWK, any>({
        path: `/holders/jwks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes own Json Web Key (JWK) by kid
     *
     * @tags Holders API
     * @name RemoveOwnJwk
     * @request DELETE:/holders/jwks
     * @secure
     */
    removeOwnJwk: (
      query: {
        /** Id of the JWK to remove */
        kid: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/holders/jwks`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Gets all public Json Web Key (JWK) from holder Note you need to be logged in to the wallet to use this method.
     *
     * @tags Holders API
     * @name GetOwnJwKs
     * @request GET:/holders/jwks
     * @secure
     */
    getOwnJwKs: (params: RequestParams = {}) =>
      this.request<JWK[], any>({
        path: `/holders/jwks`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds x.509 certificate to holder
     *
     * @tags Holders API
     * @name AddX509
     * @request POST:/holders/{holderId}/x509
     * @secure
     */
    addX509: (
      holderId: any,
      data: {
        publickey: string;
        privatekey: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/holders/${holderId}/x509`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Adds x.509 certificate to holder (requires wallet authentication)
     *
     * @tags Holders API
     * @name AddOwnX509
     * @request POST:/holders/x509
     * @secure
     */
    addOwnX509: (
      data: {
        publickey: string;
        privatekey: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/holders/x509`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Gets all public Json Web Key (JWK) from holder
     *
     * @tags Holders API
     * @name GetJwKs
     * @request GET:/holders/{name}/jwks
     */
    getJwKs: (name: string, params: RequestParams = {}) =>
      this.request<JWK[], any>({
        path: `/holders/${name}/jwks`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Returns did.json
     *
     * @tags Holders API
     * @name GetDidJson
     * @request GET:/holders/{name}/did.json
     */
    getDidJson: (name: string, params: RequestParams = {}) =>
      this.request<DidJson | null, any>({
        path: `/holders/${name}/did.json`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Gets holder's did:key, requires logged in user
     *
     * @tags Holders API
     * @name GetDidKeyAuthenticated
     * @request GET:/holders/did/key
     * @secure
     */
    getDidKeyAuthenticated: (
      query?: {
        kid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JWT, any>({
        path: `/holders/did/key`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Gets holder's did:key.
     *
     * @tags Holders API
     * @name GetDidKey
     * @request GET:/holders/{holderId}/did/key
     */
    getDidKey: (
      holderId: any,
      query?: {
        kid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JWT, any>({
        path: `/holders/${holderId}/did/key`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Gets holder's did:jwk
     *
     * @tags Holders API
     * @name GetDidJwk
     * @request GET:/holders/{holderId}/did/jwk
     */
    getDidJwk: (
      holderId: string,
      query?: {
        kid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<JWT, any>({
        path: `/holders/${holderId}/did/jwk`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Get credentials
     *
     * @tags Holders API
     * @name GetCredentials
     * @request GET:/holders/credentials
     * @secure
     */
    getCredentials: (
      query?: {
        /** Filter credentials by type */
        type?: string;
        /**
         * Skip first results, used for paging
         * @format double
         */
        skip?: number;
        /**
         * Amount of maximum results to load
         * @format double
         */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          credentials: CredentialInfo[];
          /** @format double */
          totalCount: number;
        },
        any
      >({
        path: `/holders/credentials`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get logs for holder If count is true, it will return total number of logs (without skip/take), otherwise it return -1 (which means the total number is not known)
     *
     * @tags Holders API
     * @name GetLogs
     * @request GET:/holders/logs
     * @secure
     */
    getLogs: (
      query?: {
        typesCSV?: string;
        /** @format double */
        skip?: number;
        /** @format double */
        take?: number;
        count?: boolean;
        fromUTC?: string;
        toUTC?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format double */
          take?: number;
          /** @format double */
          skip?: number;
          /** @format double */
          count: number;
          logs: LogEntity[];
        },
        any
      >({
        path: `/holders/logs`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Deletes all logs from the holder If count is true, it will return total number of logs (without skip/take), otherwise it return -1 (which means the total number is not known)
     *
     * @tags Holders API
     * @name DeleteLogs
     * @request DELETE:/holders/logs
     * @secure
     */
    deleteLogs: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/holders/logs`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holders API
     * @name DeleteCredential
     * @request DELETE:/holders/credentials/{id}
     * @secure
     */
    deleteCredential: (id: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/holders/credentials/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Handle credential offer Issuer can send credential directly to wallet by using this method. Either credential_offer or credential_offer_uri needs to be set
     *
     * @tags Holders API
     * @name CredentialOffer
     * @request GET:/holders/{holderId}/credential_offer
     */
    credentialOffer: (
      holderId: string,
      query?: {
        /** The credential offer as JSON object */
        credential_offer?: string;
        /** The URI where the credential offer can be found */
        credential_offer_uri?: string;
        tx_code?: string;
        /** PIN code need to be used to get the credential (optional) */
        pin_code?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/holders/${holderId}/credential_offer`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description Handle credential offer Issuer can send credential directly to wallet by using this method. Either credential_offer or credential_offer_uri needs to be set
     *
     * @tags Holders API
     * @name CredentialOfferPost
     * @request POST:/holders/{holderId}/credential-offer
     */
    credentialOfferPost: (holderId: string, data?: JWT, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/holders/${holderId}/credential-offer`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Not fully implemented yet ... TODO check permissions for automatic reply / or ask user to approve presentation
     *
     * @tags Holders API
     * @name Authorize
     * @request GET:/holders/{holderId}/authorize
     */
    authorize: (
      holderId: string,
      query: {
        client_id: string;
        request_uri: string;
        presentation_definition?: string;
        presentation_definition_uri?: string;
        client_metadata?: string;
        request_uri_method?: string;
        nonce?: string;
        response_type?: string;
        redirect_uri?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        | {
            redirect_uri?: string;
            [key: string]: any;
          }
        | string,
        any
      >({
        path: `/holders/${holderId}/authorize`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description End-point which wallet uses to start processing credential offer from the link or QR code.
     *
     * @tags Holders API
     * @name InitiateCredentialOffer
     * @request POST:/holders/initiate/credentialOffer
     * @secure
     */
    initiateCredentialOffer: (
      data: JWT,
      query?: {
        pinCode?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        | CredentialInfo[]
        | {
            redirect: string;
          },
        any
      >({
        path: `/holders/initiate/credentialOffer`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holders API
     * @name ContinueCredentialOffer
     * @request GET:/holders/{holderId}/continue/credentialOffer
     */
    continueCredentialOffer: (
      holderId: string,
      query: {
        state: string;
        code: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        | CredentialInfo[]
        | {
            redirect: string;
          },
        any
      >({
        path: `/holders/${holderId}/continue/credentialOffer`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Holder UI is calling this to get information which credentials are requested for presentation
     *
     * @tags Holders API
     * @name GetPresentationDefinition
     * @request GET:/holders/presentationDefinition
     */
    getPresentationDefinition: (
      query: {
        /** openid4vp:// url */
        openid4vpUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PresentationDefinition | null, any>({
        path: `/holders/presentationDefinition`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Generic end point for presentation and offering
     *
     * @tags Holders API
     * @name InitiateVeriablePresentation
     * @request POST:/holders/initiate/verifiablePresentation
     * @secure
     */
    initiateVeriablePresentation: (
      data: InitiatePresentationPayload,
      query?: {
        nonce?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          redirect_uri?: string;
        },
        any
      >({
        path: `/holders/initiate/verifiablePresentation`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holders API
     * @name InitiateOpenIdRequest
     * @request POST:/holders/initiate/openIdRequest
     * @secure
     */
    initiateOpenIdRequest: (data: InitiateIdTokenPresentationPayload, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/holders/initiate/openIdRequest`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holders API
     * @name GetUsers
     * @request GET:/holders/users
     * @secure
     */
    getUsers: (params: RequestParams = {}) =>
      this.request<
        {
          users: HolderUserPayload[];
        },
        any
      >({
        path: `/holders/users`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Adds user to holder wallet
     *
     * @tags Holders API
     * @name AddUser
     * @request POST:/holders/users
     * @secure
     */
    addUser: (data: HolderUserCreatePayload, params: RequestParams = {}) =>
      this.request<HolderUserPayload, any>({
        path: `/holders/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates existing user in holder wallet (or creates new if not exists)
     *
     * @tags Holders API
     * @name UpdateUser
     * @request PUT:/holders/users
     * @secure
     */
    updateUser: (data: HolderUserPayload, params: RequestParams = {}) =>
      this.request<HolderUserPayload, any>({
        path: `/holders/users`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes user from holder wallet
     *
     * @tags Holders API
     * @name DeleteUser
     * @request DELETE:/holders/users/{userId}
     * @secure
     */
    deleteUser: (userId: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/holders/users/${userId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}

import React, { useEffect, useState } from 'react'
import vceApi from '../vceApi';
import QRCode from 'react-qr-code';
import { Button, Dropdown, DropdownItem, Paragraph, TextInput } from 'suomifi-ui-components';
import { t } from 'i18next';
import { useQuery } from "@tanstack/react-query";

type Props = { 
  issuer: string,
  credentialType: string,
  credentialConfigurationId: string,
  kids: string[]|undefined,
  credentialSubject: string //{ [key: string]: unknown }
}

/** 
 * DO NOT USE THIS IN PRODUCTION! 
 * Issuing should happen in back channel, and there should be also issuer related apikey or something else to prevent anyone to issue credentials who can access the API
 * MiniSuomi environment has only test data 
 **/
const IssueCredential = ({ issuer, credentialType, credentialConfigurationId, credentialSubject, kids }: Props) => {
  debugger;
  const [error, setError] = useState<string>("");
  const [toggleSettings, setToggleSettings] = useState<boolean>(false)
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [kid, setKid] = useState<string>("");
  const [overrideType, setOverrideType] = useState<string>(credentialType);

  const { isPending, error: errorQuery, data: credentialOfferUrl} = useQuery<string>({
    queryKey: ['issue', kid, issuer, credentialConfigurationId, credentialSubject, overrideType],
    queryFn: () =>
      vceApi.issuers.issue(issuer, credentialConfigurationId, JSON.parse(credentialSubject), { 
          validFrom: new Date().toISOString() /*validFrom?.toISOString()*/, 
          validTo: undefined /*validTo?.toISOString()*/, 
          kid: kid ?? undefined,
          overrideType: overrideType
        }).then((res) => 
        res.text(),
      ),
    enabled: !!issuer && !!credentialConfigurationId
  })

  
  if (isPending) return <p>Loading...</p>
  if (errorQuery) return <p>An error has occurred: { errorQuery.message }</p>


  // TODO renew button and time out (5min)

  const onSendCredentialToWalletClick = () => {
    if (!walletAddress)
      return;
    debugger;

    // Trim wallet address and try to post open id request to the address
    const walletAddressTrimmed = walletAddress.trim();
    const credential_offer_uri = new URL(credentialOfferUrl).searchParams.get("credential_offer_uri");
    //credential_offer_uri.
    //const decodedUri = decodeURIComponent(credentialOfferUrl);
    const issuer_state = credential_offer_uri?.substring(credential_offer_uri.lastIndexOf("?id=")+4);
    if (walletAddressTrimmed.length > 0 && issuer_state) {
      
      vceApi.issuers.initiateCredentialOffer("prh", { walletAddress: walletAddressTrimmed, issuer_state: issuer_state }).then(x => {
        alert("credential has been sent to wallet");
        debugger;
      }).catch(x => {
        debugger;
        setError(JSON.stringify(x));
      })

    }
  }

  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <span style={{ color: "red" }}>{ error }</span>
      
      {/* <Button onClick={applyChanges}>{t("issueCredential.applyChanges")} </Button> */}
      
      
      <Paragraph style={{color: toggleSettings ? "#003479" : "#ccc", cursor: "pointer"}} onClick={() => {setToggleSettings(!toggleSettings)}}>{toggleSettings ? 'hide advanced settings' : 'show advanced settings'}</Paragraph>
      { toggleSettings && <>
        <TextInput readOnly={false} name='type' labelText={t("issueCredential.type")} value={overrideType??credentialType} onChange={(e: any)=>setOverrideType(e)}></TextInput> 
        { kids &&
        
          <Dropdown
            key="kidDropdown"
            labelText="KID"
            hintText="KID is used to identify signature keys"
            visualPlaceholder="Select KID to use"
            value={kid}
            onChange={e => setKid(e)}
          >
            { kids?.map(kid => 
              <DropdownItem key={kid} value={kid}>{kid}</DropdownItem>
            )}     
          </Dropdown>
          
        }
        <br/>
        </>
      }

      { credentialOfferUrl && !error &&
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 192, width: "100%" }}>
          <a href={credentialOfferUrl}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={credentialOfferUrl}
              viewBox={`0 0 256 256`}
            />
          </a>
          <p style={{textAlign: "center", margin: 0}}>{credentialType}</p>
        </div>
      }
      <div style={{display:"flex", flexDirection:"row", /*maxWidth: "800px",*/ alignItems: "end"/*, gap: "10px"*/}}>
        <TextInput readOnly={false} fullWidth className='longer-input' name='walletAddress' labelText={t("issueCredential.walletAddress")} value={walletAddress} onChange={(e) => setWalletAddress(e as string)}></TextInput>
        <Button style={{marginBottom: "7px", minWidth: "250px"}} disabled={!walletAddress} onClick={() => onSendCredentialToWalletClick() }>{t("issueCredential.sentToWallet")}</Button>
      </div>
    
        
    </div>
  )
}

export default IssueCredential